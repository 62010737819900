<div class="modal-body text-center p-5">
    <lord-icon src="https://cdn.lordicon.com/kbtmbyzy.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style="width:120px;height:120px"></lord-icon>
    <div class="mt-4">
        <h4 class="mb-3">Your Session is about to expire!</h4>
        <p class="text-muted mb-4"> For your security, this session will expire in <b class="text-danger">00:{{ countdown }}</b> due to inactivity.</p>
        <!-- <p class="text-muted mb-4">For your security, this session will expire in <b class="text-danger"> {{ currentTimeCount  }} seconds </b> due to inactivity.</p> -->

        <p class="text-muted mb-4">Do you want to extend your session? please select <b>Continue</b>.</p>
        <p class="text-muted mb-4">If you select <b>Log Out</b> or do not respond your session will automatically close. </p>
        <!-- <p class="text-muted mb-4">CURRENT: {{ currentTime  }} seconds</p>-->
        <div class="hstack gap-2 justify-content-center">
          <button class="btn btn-primary" (click)="extendSession()">Continue</button>
          <button class="btn btn-secondary" (click)="logout()">Log Out</button>
        </div>
    </div>
</div>
