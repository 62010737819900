import { MODULES } from 'src/app/evergrow/constants/app.constants';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    module: MODULES.DASHBOARD,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    collapseid: 'sidebarDashboards',
    icon: 'bx bxs-dashboard',
    link: '/',
  },
  {
    id: 2,
    module: MODULES.CRM,
    label: 'MENUITEMS.CRM.TEXT',
    collapseid: 'sidebarCrm',
    icon: 'bx bx-layer-plus',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.CRM.LIST.COMPANIES',
        link: '/crm/companies',
        parentId: 2
      },
      {
        id: 2,
        label: 'MENUITEMS.CRM.LIST.CONTACTS',
        link: '/crm/contacts',
        parentId: 2
      },
      {
        id: 3,
        label: 'MENUITEMS.CRM.LIST.DEALS',
        link: '/crm/deals',
        parentId: 2
      },
      {
        id: 4,
        label: 'MENUITEMS.CRM.LIST.LEADS',
        link: '/crm/leads',
        parentId: 2
      },
      {
        id: 5,
        label: 'MENUITEMS.CRM.LIST.SETTINGS',
        link: '/crm/settings',
        parentId: 2
      },
    ]
  },
  {
    id: 3,
    module: MODULES.COMMUNICATIONS,
    label: 'MENUITEMS.CONNECTIONS.TEXT',
    collapseid: 'sidebarConnections',
    icon: 'bx bx-chat',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.CONNECTIONS.LIST.CONTACT',
        link: '/connections/contacts',
        parentId: 3
      },
      {
        id: 2,
        label: 'MENUITEMS.CONNECTIONS.LIST.COMPANY',
        link: '/connections/companies',
        parentId: 3
      },
    ]
  },
  {
    id: 4,
    module: MODULES.COMMERCE,
    label: 'MENUITEMS.COMMERCE.TEXT',
    collapseid: 'sidebarCommerce',
    icon: 'bx bx-cart',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.COMMERCE.LIST.COMMERCE_DECK',
        link: '/commerce/dashboard',
        parentId: 4
      },
      {
        id: 2,
        label: 'MENUITEMS.COMMERCE.LIST.SUPPLIERS',
        link: '/commerce/suppliers',
        parentId: 4
      },
      {
        id: 3,
        label: 'MENUITEMS.COMMERCE.LIST.BRANDS',
        link: '/commerce/brands',
        parentId: 4
      },
      {
        id: 4,
        label: 'MENUITEMS.COMMERCE.LIST.CATEGORIES',
        link: '/commerce/categories',
        parentId: 4
      },
      {
        id: 5,
        label: 'MENUITEMS.COMMERCE.LIST.PRODUCTS',
        link: '/commerce/products',
        parentId: 4
      },
      {
        id: 6,
        label: 'MENUITEMS.COMMERCE.LIST.TEMPLATES',
        link: '/commerce/templates',
        parentId: 4
      },
      {
        id: 7,
        label: 'MENUITEMS.COMMERCE.LIST.QUOTES',
        link: '/commerce/quotes',
        parentId: 4
      },
      {
        id: 8,
        label: 'MENUITEMS.COMMERCE.LIST.SALES',
        link: '/commerce/sales',
        parentId: 4
      },
      {
        id: 9,
        label: 'MENUITEMS.COMMERCE.LIST.PURCHASES',
        link: '/commerce/purchases',
        parentId: 4
      },
      {
        id: 10,
        label: 'MENUITEMS.COMMERCE.LIST.REPORTS',
        link: '/commerce/reports',
        parentId: 4
      },
      {
        id: 11,
        label: 'MENUITEMS.COMMERCE.LIST.SETTINGS',
        link: '/commerce/settings',
        parentId: 4
      },
    ]
  },
  {
    id: 5,
    module: MODULES.ACCOUNTS,
    label: 'MENUITEMS.ACCOUNTS.TEXT',
    collapseid: 'sidebarAccounts',
    icon: 'bx bx-calculator',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.ACCOUNTS.LIST.ACCOUNTSLIST',
        link: '/accounts',
        parentId: 5
      },
    ]
   },

   {
    id: 6,
    module: MODULES.SALES_MARKETING,
    label: 'MENUITEMS.SALESMARKETING.TEXT',
    collapseid: 'sidebarSalesMarketing',
    icon: 'bx bx-dollar-circle',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.SALESMARKETING.LIST.SALESMARKETINGLIST',
        link: '/salesmarketing',
        parentId:6
      },
    ]
   },
   {
    id: 7,
    module: MODULES.HUMAN_RESOURCE,
    label: 'MENUITEMS.HUMANRESOURCE.TEXT',
    collapseid: 'sidebarHumanresource',
    icon: 'bx bx-happy-beaming',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.HUMANRESOURCE.LIST.HUMANRESOURCELIST',
        link: '/humanresource',
        parentId:7
      },
    ]
   },
   {
    id: 8,
    label: 'MENUITEMS.DOCUMENTS.TEXT',
    collapseid: 'sidebarDocuments',
    icon: 'bx bx-folder-open',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.DOCUMENTS.LIST.DOCUMENTSLIST',
        link: '/documents',
        parentId:8
      },
    ]
   },
   {
    id: 9,
    module: MODULES.SETTING,
    label: 'MENUITEMS.SETTINGS.TEXT',
    collapseid: 'sidebarSettings',
    icon: 'bx bx-cog',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.SETTINGS.LIST.ORGANISATION',
        link: '/organisation',
        parentId:9
      },
      {
        id: 2,
        label: 'MENUITEMS.SETTINGS.LIST.USERACCOUNTS',
        link: '/useraccounts',
        parentId:9
      },
      {
        id: 3,
        label: 'MENUITEMS.SETTINGS.LIST.INTEGRATIONS',
        link: '/integrations',
        parentId:9
      },
      {
        id: 4,
        label: 'MENUITEMS.SETTINGS.LIST.MODULES',
        link: '/modules',
        parentId:9
      },
    ]
   },
   {
    id: 10,
    label: 'MENUITEMS.SUBSCRIPTION.TEXT',
    collapseid: 'sidebarSubscription',
    icon: 'bx bx-user-circle',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.SUBSCRIPTION.LIST.SUBSCRIPTIONLIST',
        link: '/subscription',
        parentId:10
      },
    ]
   },

];
