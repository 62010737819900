import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutModalComponent } from 'src/app/account/session-timeout-modal/session-timeout-modal.component';
import { GlobalComponent } from 'src/app/global-component';

@Injectable({
  providedIn: "root",
})
export class SessionService implements OnDestroy {
  private readonly SESSION_TIMEOUT = 60 * 60 * 1000; //600000; // 60 minutes in milliseconds
  private timeoutId: any;
  public isSessionActive: boolean = false;
  public isSessionLogout: boolean = false;

  public expiryTimestamp: any;
  public remainingTime: any;

  constructor(
    private modalService: NgbModal,
    private http: HttpClient
    ) {
    this.updateExpiryTimestamp();
  }

  sendTimeZone(timezone: string) {
    console.log("Browser timezone", timezone);
    return this.http.post(GlobalComponent.AUTH_API + 'timezone', { timezone });
  }

  sendWebUrl(weburl: string) {
    console.log("weburl", weburl);
    return this.http.post(GlobalComponent.AUTH_API + 'weburl', { weburl });
  }

  updateExpiryTimestamp() {
    this.expiryTimestamp = Date.now() + this.SESSION_TIMEOUT;
    var userStr = localStorage.getItem("currentUser") || "";
    if (userStr) {
      this.isSessionActive = true;
    }
    this.addListeners();
    this.resetTimer();
  }

  resetTimer(): void {
    this.expiryTimestamp = Date.now() + this.SESSION_TIMEOUT;
    clearTimeout(this.timeoutId);
    this.remainingTime = this.expiryTimestamp - Date.now();
    if (this.remainingTime > 0) {
      this.timeoutId = setTimeout(() => {
        if (this.isSessionActive) {
          this.showSessionTimeoutModal();
        }
      }, this.remainingTime);
    }
  }

  showSessionTimeoutModal(): void {
    const modalRef = this.modalService.open(SessionTimeoutModalComponent, {
      backdrop: "static", // Prevents closing the modal by clicking outside
      keyboard: false, // Prevents closing the modal by pressing ESC key
      centered: true,
    });

    // modalRef.result.then((result) => {
    //     if (result === 'extend') {
    //         this.resetTimer();
    //     } else {
    //         // Perform logout or any other desired action
    //     }
    // });
  }

  extendSession(): void {
    clearTimeout(this.timeoutId);
    this.updateExpiryTimestamp();
    // this.resetTimer();
  }

  ngOnDestroy() {
    this.updateExpiryTimestamp();
    clearTimeout(this.timeoutId);
  }

  addListeners(): void {
    window.addEventListener("mousemove", this.resetTimer.bind(this));
    window.addEventListener("keydown", this.resetTimer.bind(this));
    window.addEventListener("click", this.resetTimer.bind(this));
    window.addEventListener("scroll", this.resetTimer.bind(this));
  }
}
