// lock.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LockGuard implements CanActivate {

  currentUrl:string | null = null;
    
  constructor(
    private authService: AuthenticationService, 
    private router: Router
  ) {
    this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url
      });
  }


  canActivate(): boolean {
  this.authService.isUserLocked().subscribe((data:any)=>{
    if(data){
        this.router.navigate(['/lock-screen']);
        return false;
    }
    else{
        if(this.currentUrl =="/lock-screen"){
          this.router.navigate(['/'])
        }
        return true
    }
  },(error:any) => {
    return false
    console.log(error);
  })
    return true;

  }
}
