<div class="auth-page-wrapper auth-fade-in pt-5 ">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
        <div class="bg-overlay"></div>
  
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
  
    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <img src="assets/images/logo-light.png" alt="" height="20">
                            </a>
                        </div>
                        <!-- <p class="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> -->
                    </div>
                </div>
            </div>
            <!-- end row -->
  
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card mt-4">
  
                        <div class="card-body p-4">
                            <div class="text-center mt-2">
                                <h5 class="text-primary">Lock Screen</h5>
                                <p class="text-muted">Enter your password to unlock the screen!</p>
                            </div>
                            <div class="user-thumb text-center">
                                <div *ngIf="profile.has_image !== null; else fallback">
                                    <img [src]="profile?.has_image" class="rounded-circle img-thumbnail avatar-lg"
                                        alt="thumbnail">
                                </div>
                                <ng-template #fallback>
                                    <img [src]="fallback_image_url" class="rounded-circle img-thumbnail avatar-lg"
                                        alt="thumbnail">

                                </ng-template>
                                <h5 class="font-size-15 mt-3">{{profile?.fullName}}</h5>
                            </div>
                            <div class="p-2 mt-4">
                                <form [formGroup]="lockscreenForm" (ngSubmit)="onSubmit()">
                                    <div class="mb-3">
                                        <label class="form-label" for="userpassword">Password</label>
                                        <input type="password" class="form-control" id="userpassword" placeholder="Enter password" required formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback" align="left">
                                          <div *ngIf="f['password'].errors['required']">Password is required</div>
                                        </div>
                                    </div>
                                    <div class="mb-2 mt-4">
                                        <button class="btn btn-success w-100" type="submit">Unlock</button>
                                    </div>
                                </form><!-- end form -->
  
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
  
                    <!-- <div class="mt-4 text-center">
                        <p class="mb-0">Not you ? return <a routerLink="/auth/signin/basic" class="fw-semibold text-primary text-decoration-underline"> Signin </a> </p>
                    </div> -->
  
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->
  
    <!-- footer -->
    <footer class="footer">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="text-center">
                      <p class="mb-0 text-muted">&copy; {{year}} EverGrow
                  </div>
              </div>
          </div>
      </div>
  </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->  