/**
 * 
 * @param matches //Content where all the dynamic variables are checked to replace Placeholders
 * @param properties //Getting current object of the called class
 * @returns 
 */
export function replacePlaceHolders(matches: string, properties: any): string {
    const placeholderToProperty: Record<string, string> = {
        '{Company Name}': 'company_name',
        '{Trading Name}': 'trading_name',
        '{ABN}': 'abn',
        '{ACN}': 'acn',
        '{Company Phone}': 'phone',
        '{Company Fax}': 'fax',
        '{Company Email}': 'email',
        '{Company Primary Address}': 'primary_address',
        '{Recipient Company Name}': 'recipient_company_name',
        '{Recipient Email}': 'recipient_email',
        '{Recipient Phone}': 'recipient_phone',
        '{Recipient Delivery Address}': 'recipient_delivery_address',
        '{Quote Title}': 'quote_title',
        '{Quote Number}': 'quote_number',
        '{Created Date}': 'created_date',
        '{Expiry Date}': 'expiry_date',
        '{Order Total}': 'order_total',
        '{Delivery Address}': 'delivery_address',
        '{Discount}': 'discount'
    };

    const pattern = new RegExp(Object.keys(placeholderToProperty).join('|'), 'g');

    return matches.replace(pattern, (placeholder) => {
        const property = placeholderToProperty[placeholder];
        return properties[property] || 'N/A';  //property: if property doesn't exist, return 'N/A'
    });
}
