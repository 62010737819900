import { Component, OnInit } from '@angular/core';
import { SessionService } from './core/services/session.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EverGrow';

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.sessionService.resetTimer();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.sendTimeZoneToServer();
    this.sendWebUrlToServer();
  }

  /**
   * Send Current timezone from browser to api
   */
  sendTimeZoneToServer(): void {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.sessionService.sendTimeZone(userTimeZone)
    .subscribe(
      response => {
        console.log('Time zone sent to server', response);
      },
      error => {
        console.error('Error sending time zone', error);
      }
    );
  }

  /**
   * Send Current timezone from browser to api
   */
  sendWebUrlToServer(): void {
    const weburl = environment.WEB_URL;
    this.sessionService.sendWebUrl(weburl)
    .subscribe(
      response => {
        console.log('Web Url sent to server', response);
      },
      error => {
        console.error('Error sending Web Url', error);
      }
    );
  }

}
