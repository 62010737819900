
export const STATE = {
  INACTIVE: 0,
  ACTIVE: 1,
  INVITE: 2,
  DELETED: 3,
}

export const ISHEAD = {
  YES: 1,
  NO: 0,
}
export const PERMISSIONS = {
  READ: 'R',
  WRITE: 'W',
  ANY: '*'
}

export const MODULES = {
  SETTING: 1,
  HUMAN_RESOURCE: 2,
  ACCOUNTS: 3,
  COMMERCE: 4,
  SALES_MARKETING: 5,
  CRM: 6,
  COMMUNICATIONS: 7,
  DASHBOARD: 8
}
export const ROLE_ID = {
  ADMIN: 1,
  HRADMIN: 2,
  FINANCE: 3,
  USER: 4,
}

export const stateList = [
  { id: STATE.INACTIVE, name: 'Inactive', class: 'badge badge-pill badge-soft-warning fs-12 ' },
  { id: STATE.ACTIVE, name: 'Active', class: 'badge badge-pill badge-soft-success fs-12' },
  { id: STATE.INVITE, name: 'Invite', class: 'badge badge-pill badge-soft-info fs-12' },
  { id: STATE.DELETED, name: 'Deleted', class: 'badge badge-pill badge-soft-danger fs-12' },
]

export const stateMap: any = stateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});

export const QUOTE_TEMPLATE_STATE = {
  INACTIVE: 0,
  ACTIVE: 1,
  DRAFT: 2,
  ACCEPTED: 3,
  DECLINED: 4,
}

export const quoteTemplateStateList = [
  { id: QUOTE_TEMPLATE_STATE.INACTIVE, name: 'Inactive', class: 'badge-soft-primary' },
  { id: QUOTE_TEMPLATE_STATE.ACTIVE, name: 'Active', class: 'badge-soft-secondary' },
  { id: QUOTE_TEMPLATE_STATE.DRAFT, name: 'Draft', class: 'badge-soft-warning' },
  { id: QUOTE_TEMPLATE_STATE.ACCEPTED, name: 'Accepted', class: 'badge-soft-success' },
  { id: QUOTE_TEMPLATE_STATE.DECLINED, name: 'Declined', class: 'badge-soft-danger' },
]

export const quoteTemplateStateMap: any = quoteTemplateStateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});


// export const QUOTE_ORDER_STATE = {
//   CANCELLED: 0,
//   APPROVED: 1,
//   RECEIVED: 2,
// }

// export const quoteOrderStateList = [
//   { id: QUOTE_ORDER_STATE.CANCELLED, name: 'Inactive', class: 'badge-soft-danger' },
//   { id: QUOTE_ORDER_STATE.APPROVED, name: 'Active', class: 'badge-soft-success' },
//   { id: QUOTE_ORDER_STATE.RECEIVED, name: 'Draft', class: 'badge-soft-warning' },
// ]

// export const quoteOrderStateMap: any = quoteOrderStateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});


export const ORDER_STATE = {
  RECEIVED: 0,
  APPROVED: 1,
  CANCELLED: 2,
}

export const orderStateList = [
  { id: ORDER_STATE.RECEIVED, name: 'Received', class: 'badge-soft-primary' },
  { id: ORDER_STATE.APPROVED, name: 'Approved', class: 'badge-soft-success' },
  { id: ORDER_STATE.CANCELLED, name: 'Cancelled', class: 'badge-outline-danger' },
]

export const orderStateMap: any = orderStateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});


export const FULFILLMENT_STATE = {
  NOT_SHIPPED: 0,
  SHIPPED: 1,
  NO_SHIPMENT: 2,
}

// export const fulfillmentStateList = [
//   { id: FULFILLMENT_STATE.NO_SHIPMENT, name: 'No Shipment', class: 'badge-soft-info' },
//   { id: FULFILLMENT_STATE.SHIPPED, name: 'Shipped', class: 'badge-soft-secondary' },
//   { id: FULFILLMENT_STATE.NOT_SHIPPED, name: 'Not Shipped', class: 'badge-soft-warning' },
// ]
export const fulfillmentStateList = [
  { id: FULFILLMENT_STATE.NO_SHIPMENT, name: 'No Shipment', class: 'badge-soft-info' },
  { id: FULFILLMENT_STATE.SHIPPED, name: 'Completed', class: 'badge-soft-secondary' },
  { id: FULFILLMENT_STATE.NOT_SHIPPED, name: 'Pending', class: 'badge-soft-warning' },
]


export const fulfillmentStateMap: any = fulfillmentStateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});

export const PAYMENT_STATE = {
  NOT_PAID: 0,
  FULLY_PAID: 1,
  PARTLY_PAID: 2,
}

export const paymentStateList = [
  { id: PAYMENT_STATE.NOT_PAID, name: 'Not Paid', class: 'badge-soft-info' },
  { id: PAYMENT_STATE.FULLY_PAID, name: 'Fully Paid', class: 'badge-soft-secondary' },
  { id: PAYMENT_STATE.PARTLY_PAID, name: 'Partly Paid', class: 'badge-soft-warning' },
]

export const paymentStateMap: any = paymentStateList.reduce((map, state) => (Object.assign(map, { [state.id]: state })), {});

export const timezoneList = [
  {name: 'Australia/Sydney'},
  {name: 'Australia/Melbourne'},
  {name: 'Australia/Brisbane'},
  {name: 'Australia/Perth'},
  {name: 'Australia/Adelaide'},
  {name: 'Australia/Canberra'},
  {name: 'Australia/Hobart'},
  {name: 'Australia/Darwin'},
  {name: 'Australia/Townsville'},
]