import { ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
/**
 * Select the row on datatable and highlight affected row.
 * @param id //id of the data
 * @param className // 'bg-soft-success', 'bg-soft-danger'
 */
export function highlightRow(id: any, className?: any) {
  const affectedRow = document.querySelector(`[id="c_${id}"]`) as HTMLElement;
  if (affectedRow) {
    affectedRow.classList.add(className);
  }
}

export function removeHighlight(className: any) {
  const affectedRow = document.querySelector('.' + className) as HTMLElement;
  if (affectedRow) {
    affectedRow.classList.remove(className);
  }
}

/**
 * Popup message dialog with highlighing affected row
 * @param showTitle  // Heading Eg: Added!
 * @param showText   // Message Eg; Data Added Successfully! 
 * @param showIcon  // icon class Eg: success
 * @param highlightId  // id of the data
 * @param highlightClass // 'bg-soft-success', 'bg-soft-danger'
 */
export function showToastWithHighlightRow(showTitle:any, showText:any, showIcon:any, highlightId?:any, highlightClass?: any) {
    let timerInterval: any;
    Swal.fire({
      title: showTitle,
      text: showText,
      icon: showIcon,
      confirmButtonColor: '#299cdb',
      timer: 2000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
  
      //in case of bulk delete
      if (highlightId && typeof(highlightId)== 'object') {
        highlightId.map((id: any) => {
          highlightRow(id, highlightClass)
        })
      }
      //in case of single delete
      else{
        highlightRow(highlightId, highlightClass)
      }
      
      if (result.dismiss === Swal.DismissReason.timer) {}
    });
  }


/**
 * Popup message dialog 
 * @param showTitle  // Heading Eg: Added!
 * @param showText   // Message Eg; Data Added Successfully! 
 * @param showIcon  // icon class Eg: success
 */
export function showToast(showTitle:any, showText:any, showIcon:any) {
    let timerInterval: any;
    Swal.fire({
      title: showTitle,
      text: showText,
      icon: showIcon,
      confirmButtonColor: '#299cdb',
      timer: 2000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      
      
      if (result.dismiss === Swal.DismissReason.timer) {}

    });
  }

  /**
   * 
   * @param showTitle //Title
   * @param showText  //Content Message
   * @param showIcon // Icon for Message
   */
  export function showToastNoTimer(showTitle:any, showText:any, showIcon:any) {
    let timerInterval: any;
    Swal.fire({
      title: showTitle,
      text: showText,
      icon: showIcon,
      confirmButtonColor: '#299cdb',
    });
  }
  