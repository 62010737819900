<form (ngSubmit)="saveQuote(0)" class="tablelist-form" autocomplete="off" *ngIf="!tokenExpired">

    <div class="row justify-content-center p-5">
  
      <div class="col-xxl-9">
        <form [formGroup]="quoteRowForm">
          <div class="card" id="template-container">
            <div class="card-body p-0">
              <div class="row add-row-selection " id="add-row-selection-{{i}}" formArrayName="rows"
                *ngFor="let row of rows.controls; let i = index;" >
                <div [formGroupName]="i">
                  <div class="col team-settings bg-soft-info">
                    <div class="row">
                      <input type="hidden" [value]="i" (input)="row.get('row_index')?.setValue(i)"
                        formControlName="row_index" />
  
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mt-lg-0 p-0">
                      <!-- ALL DESIGNS -->
                      <!-- TextEditor with CKEditor -->
                      <div *ngIf="row.value.row_type === 'textEditor'">
                          <div class="p-4" [innerHTML]="sanitizeHtml(row.value.row_text_data)"
                              [style.background]="row.value.row_background_color"
                              [style.min-height]="row.value.row_height">
                              {{row.value.row_text_data}}
                          </div>
                      </div>
                      <div *ngIf="row.value.row_type === 'imageEditor'">
                          <div class="row">
                              <div class="col-lg-12">
                                  <div class="position-relative mb-0 mt-0 overflow-hidden">
                                      <div class="text-editor-overlay p-4" id="editor-container"
                                          [style.background]="row.value.row_background_color"
                                          [style.min-height]="row.value.row_height">
                                          <div class="transparent-editor p-4"
                                              [innerHTML]="sanitizeHtml(row.value.row_overlay_text)">
                                              {{row.value.row_overlay_text}}
                                          </div>
                                      </div>
                                      <img [src]="row.value.row_background_image || fallback_image_url"
                                          alt="" id="cover-img-{{ i }}" width="100%"
                                          class="transparent-editor-img"
                                          [style.max-height]="row.value.row_height">
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="!showDesignSelector && row.value.row_type === 'productTable'">
                        <!-- ProductTable with dynamic product entries -->
                        <div formArrayName="products">
                          <div class="table-responsive p-4" style="overflow-x: visible;" [style.background]="row.value.row_background_color"
                            [style.height]="row.value.row_height">
                            <!-- <table class="invoice-table table table align-middle table-nowrap mb-0"> -->
                            <table class="invoice-table table table-borderless text-center table align-middle table-nowrap mb-0">
                              <thead class="align-middle">
                                <tr class="table-active">
                                  <th scope="col" style="width: 10%;">MPN</th>
                                  <th scope="col" class="text-start" [ngStyle]="{'width': row.value?.tax_column == '1'  ? '35%' : '45%'}">Product Name</th>
                                  <th scope="col" class="text-start" style="width: 10%;">Quantity</th>
                                  <th scope="col" class="text-start" style="width: 15%;">Unit Rate</th>
                                  <th *ngIf="row.value?.tax_column == '1'" scope="col" class="text-start" style="width: 10%;">Tax</th>
                                  <!-- <th scope="col" class="text-start" style="width: 15%;">Amount</th> -->
                                  <th scope="col" class="text-start justify-content-start hstack gap-2" 
                                      [ngClass]="row.value?.tax_column === 1 ? 'text-info': 'text-dark'">
                                      <i class='mdi mdi-script-text-outline'></i> {{row.value?.tax_column === 1 ? 'GST Included': 'GST Excluded' }} Amount
                                  </th>
                                  <th scope="col" class="text-start" style="width: 5%;"></th>
                                </tr>
                              </thead>
                              <tbody id="newlink">
                                <tr id="1" class="product"
                                  *ngFor="let product of getProductArray(row).controls; let j = index"
                                  [formGroupName]="j">
                                  <th scope="row" class="text-start text-wrap product-id" style="width: 5%;"># {{ row.value?.products[j]?.product_mpn }}</th>
                                  <td class="text-start text-wrap ">
                                    <div class="mb-2">
                                        <div class="product-display" *ngIf="getProductById(row.value?.products[j]?.product_id)">
                                            <input type="hidden" formControlName="product_id" [value]="productDetails[row.value?.products[j]?.product_id]?.id"/>
                                            <img [src]="productDetails[row.value?.products[j]?.product_id]?.image_src" alt="product" class="options-flagimg avatar-xxs rounded-circle object-cover" height="20">
                                            <span class="fw-medium product_id-option-name p-2">{{ productDetails[row.value?.products[j]?.product_id]?.name }}</span>
                                        </div>
                                        <div class="col-sm-12 text-wrap mt-2" *ngIf="row.value.products[j]?.product_description">
                                            <p class="text-muted mb-0">{{ row.value?.products[j]?.product_description}}</p>
                                        </div>
                                    </div>
                                  </td>
                                  <td class="text-start">
                                    <div class="mb-2">
                                      <input class="form-control bg-soft-light border-opacity-50" placeholder="Quantity" mask="0*"
                                      [class.border-opacity-50]="!isAccepted || !isDeclined"
                                      [readonly]="isDisabled"
                                      [class.border-0]="isAccepted || isDeclined"
                                        formControlName="product_quantity" (change)="calculateProductAmount(product)">
                                    </div>
                                  </td>
                                  <td class="text-start">
                                    <div class="mb-2 d-flex justify-content-between align-items-center">
                                      <div class="form-icon left">  
                                        <i class="fs-15 text-dark px-2" style="font-style:normal; z-index:2; left:0;">{{paymentSign}}</i>
                                        <div class="form-control-icon form-icon right px-0">
                                          <input type="number" class="form-control form-control-icon border-0 px-4"
                                           formControlName="product_rate" (change)="calculateProductAmount(product)" readonly />
                                        </div>
                                      </div>
                                    </div>

                                  </td>
                                  
                                  <!-- <td *ngIf="row.value?.tax_column == '1'" class="text-start">
                                    {{row.value?.products[j].taxable_rate}} %
                                  </td> -->

                                  <td *ngIf="row.value?.tax_column == '1'" class="text-start position-relative">
                                    <input type="hidden" class="form-control form-control-icon bg-light border-0 me-0"
                                        formControlName="taxable" value="" placeholder="Amount" readonly />
                                    
                                    <div class="mb-2 d-flex justify-content-between align-items-center">
                                      <div class="form-icon right">  
                                        <div class="form-control-icon px-0">
                                          <input type="number" class="form-control form-control-icon border-0 px-0"
                                           formControlName="taxable_rate" placeholder="%" readonly />
                                          <i class="fs-15 text-dark px-2" style="font-style:normal; z-index:2;">%</i>  
                                        </div>
                                      </div>
                                    </div>
                                      
                                  </td>

                                  <td class="text-start position-relative">
                                    <div class="mb-2 d-flex justify-content-between align-items-center">
                                      <div class="form-icon left">  
                                        <i class="fs-15 text-dark px-2" style="font-style:normal; z-index:2; left:0;">{{paymentSign}}</i>
                                        <div class="form-control-icon form-icon right px-0">
                                          <input type="number" class="form-control form-control-icon border-0 px-4"
                                           formControlName="product_amount" placeholder="Amount" readonly />
                                          <i *ngIf="product.get('recurring')?.value == '1'" 
                                          class="fs-16 ri-repeat-2-line text-success"
                                          style="cursor: pointer;"
                                          [ngbTooltip]="row.value?.products[j].recurring_time && row.value?.products[j].recurring_cycle ? (row.value?.products[j].recurring_time + ' for ' + row.value?.products[j].recurring_cycle + ' cycle.') : 'Please create recurring.'" 
                                          placement="top"></i>
                                        </div>
                                      </div>
                                    </div>
    
                                    <span id="discount_{{j}}" *ngIf="row.value?.products[j].discount == '1'" class="position-absolute bottom-40 start-0 text-center text-success mx-3 hstack gap-2">
                                      <span *ngIf="(row.value?.products[j].product_quantity && row.value?.products[j].product_rate)" 
                                        style="text-decoration: line-through; text-decoration-color: red;"
                                        class="text-muted">
                                          {{paymentSign}} 
                                          {{getProductAfterTaxAmount(row.value?.products[j].product_quantity, row.value?.products[j].product_rate, row.value?.products[j].taxable_rate)}}
                                      </span>
                                      
                                      <span *ngIf="row.value?.products[j].discount_type === 'Percentage'" class="text-secondary badge rounded-pill badge-soft-dark">
                                        {{row.value?.products[j].discount_value}}% Off
                                      </span>
  
                                      <span *ngIf="row.value?.products[j].discount_type === 'Fixed'" class="text-secondary badge rounded-pill badge-soft-dark">
                                        {{paymentSign + + row.value?.products[j].discount_value}} Off
                                      </span>
                                    </span>
                                  </td>

                                  <td class="text-start" *ngIf="!isAccepted && !isDeclined">
                                  
                                    <div class="col text-end dropdown" ngbDropdown>
                                      <a href="javascript:void(0);" class="arrow-none" id="dropdownMenuLink2" 
                                      data-bs-toggle="dropdown" 
                                      aria-haspopup="true" 
                                      aria-expanded="true" 
                                      ngbDropdownToggle>
                                        <i class="ri-more-2-fill fs-17"></i>
                                      </a>
                                      <div class="position-absolute dropdown-menu dropdown-menu-end dropdown-menu-md p-3" style="z-index: 22" ngbDropdownMenu>
                                        <div class="row mb-1">
                                          <div class="col-xl-12 vstack gap-2">
                                            <div class="form-check form-switch form-switch-success hstack gap-2">
                                              <input class="form-check-input" type="checkbox" role="switch" 
                                              id="recurring-check-{{j}}" (change)="toggleRecurring($event, product, j)"
                                              formControlName="recurring" >
                                              <label class="form-check-label" for="recurring-check-{{j}}">Recurring</label>
                                            </div>
                                            
                                            <div id="recurring-{{j}}" *ngIf="product.get('recurring')?.value == '1'" class="px-2">
                                              <div class="hstack gap-0 input-group">
                                                  <select class="form-select form-select-sm" formControlName="recurring_time">
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Quarterly">Quarterly</option>
                                                    <option value="Yearly">Yearly</option>
                                                  </select>
                                                  <input type="number" class="form-control form-control-sm " placeholder="Cycle" formControlName="recurring_cycle">  
                                              </div>
                                            </div>
                                          </div>
                                        </div>
  
                                        <div class="row mb-1 visually-hidden">
                                          <div class="col-xl-12 vstack gap-2">
                                            <div class="form-check form-switch form-switch-success hstack gap-2">
                                              <input class="form-check-input" type="hidden" role="switch" 
                                              id="discount-check-{{j}}" 
                                              formControlName="discount" >
                                              <!-- (change)="toggleDiscount($event, product, j)" -->
                                              <label class="form-check-label" for="discount-check-{{j}}">Discount</label>
                                            </div>
                                            
                                            <div id="discount-{{j}}" *ngIf="product.get('discount')?.value == '1'" class="px-2">
                                              <div class="hstack gap-0 input-group">
                                                  <select class="form-select form-select-sm" formControlName="discount_type"><!--(change)="onChangeDiscount(product, j)"-->
                                                    <option value="Percentage">%</option>
                                                    <option value="Fixed">{{paymentSign}}</option>
                                                  </select>
                                                  <input type="hidden" [value]="0" class="form-control form-control-sm " placeholder="Cycle" 
                                                  formControlName="discount_value" max="100">  <!--(change)="onChangeDiscount(product, j)"-->
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        
                                        <div class="row mb-1">
                                          <div class="col-xl-12">
                                            <div class="mt-2">
                                              <h5 class="fs-13">Action:</h5>
                                              <button type="button" class="btn btn-danger btn-icon waves-effect waves-light"
                                                placement="top" ngbTooltip="Remove product {{ j + 1 }}" (click)="getProductArray(row).removeAt(j)"><i
                                                  class="ri-delete-bin-5-line"></i></button>
                                            </div>
                                          </div>
                                        
                                        </div>
  
  
                                      </div>
                                    </div>
  
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!showDesignSelector && row.value.row_type === 'productSummary'">
                        <!-- ProductSummary with calculations -->
                        <div class="table-responsive pt-4" [style.background]="row.value.row_background_color"
                          [style.height]="row.value.row_height">
                          <!-- <table class="invoice-table table table-borderless table-nowrap"> -->
                          <table class="table table-borderless table-nowrap align-middle mb-0 ms-auto mt-4" style="width:auto">

                            <tbody>
                              <tr class="mt-2 justify-content-center p-8 ">
                                <td colspan="3"></td>
                                <td colspan="2">
                                  <table class="table table-borderless table-sm table-nowrap align-middle">
                                    <tbody>
                                      <tr>
                                        <th scope="row">Sub Total <small class="text-muted fs-10">(before tax & discount)</small></th>
                                        <td style="width: 150px;">
                                          <div class="form-icon left">
                                            <input type="text" class="form-control form-control-icon bg-light border-0 px-4" id="cart-sub_total"
                                              placeholder="0.00" formControlName="sub_total" readonly>
                                            <i class="fs-15 px-2" style="font-style:normal; left:0;">{{paymentSign}}</i>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr *ngIf="row.value.tax_rate > 0">
                                        <th scope="row">Estimated Tax <small class="text-muted">({{tax_rate_percent *100}}%)</small>
                                          <!--################ THIS PART MAY REQUIRED FOR FUTURE TO SHOW CUSTOMER FOR TAX DETAILS ##################-->
                                          <!-- <div class="hstack gap-2 fst-italic">
                                            <div class="flex-shrink-0 text-end" id="include_tax">
                                              <h6 class="mb-1">
                                                GST Included Amount: <span class="text-center text-dark fw-semibold" style="display: none; cursor: pointer;" id="all_row_tax_included_total_amount"></span>
                                              </h6>
                                              <p class="fs-12 mb-0 "> Included Tax amount: <span class="text-muted fw-medium" id="all_row_tax_included_total"></span></p>
                                            </div>
  
                                            <div class="vr" ></div>
  
                                            <div class="flex-shrink-0 text-end" id="exclude_tax">
                                              <h6 class="mb-1">
                                                GST Excluded Amount: <span class="text-center text-dark fw-semibold" style="display: none; cursor: pointer;" id="all_row_tax_excluded_total_amount"></span>
                                              </h6>
                                              <p class="fs-12 mb-0 "> Excluded Tax amount: <span class="text-dark fw-semibold" id="all_row_tax_excluded_total"></span></p>
                                            </div>
                                          </div> -->

                                          <!-- <small class="text-muted ms-2" id="include_tax">
                                            <span class="text-center text-success me-2 text-muted badge rounded-pill badge-soft-dark"
                                                        ngbTooltip="Included Tax" placement="top" style="display: none; cursor: pointer; font-size:10.5px; margin-bottom:1.5px;" id="all_row_tax_included_total"></span>
                                          </small> -->
                                        <!--################ THIS PART MAY REQUIRED FOR FUTURE TO SHOW CUSTOMER FOR TAX DETAILS ##################-->
                                        </th>
                                        <td>
                                          <div class="form-icon left">
                                            <input type="text" class="form-control form-control-icon bg-light border-0 px-4" id="cart-tax"
                                            placeholder="0.00" formControlName="tax_rate" readonly>
                                            <i class="fs-15 px-2" style="font-style:normal; left:0;">{{paymentSign}}</i>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr *ngIf="row.value.discount_rate !== 0">
                                        <th scope="row">Discount Applied</th>
                                        <td>
                                          <div class="form-icon left text-danger">
                                            <input type="text" class="form-control form-control-icon bg-light border-0 text-danger" style="padding-left: 2.2rem !important"
                                            id="cart-discount_rate" placeholder="0.00" formControlName="discount_rate"
                                            readonly>
                                            <i class="fs-15 px-2" style="font-style:normal; left:0;">- {{paymentSign}}</i>
                                          </div>
                                          
                                        </td>
                                      </tr>
                                      <tr *ngIf="row.value.shipping_rate !== 0">
                                        <th scope="row">Shipping Applied</th>
                                        <td>
                                          <div class="form-icon left">
                                            <input type="text" class="form-control form-control-icon bg-light border-0 px-4" id="cart-shipping"
                                            placeholder="0.00" formControlName="shipping_rate" readonly>
                                            <i class="fs-15 px-2" style="font-style:normal; left:0;">{{paymentSign}}</i>
                                          </div>
                                          
                                        </td>
                                      </tr>
                                      <tr class="table-active">
                                        <th scope="row" class="fs-3">Total Amount</th>
                                        <td>
                                          <div class="form-icon left">
                                            <input type="text" class="form-control form-control-icon fs-3 fw-semibold bg-light border-0 px-4"
                                            id="cart-total" placeholder="0.00" formControlName="total_amount" readonly>
                                            <i class="fs-16 px-2" style="font-style:normal; left:0;"><b>{{paymentSign}}</b></i>
                                          </div>
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td colspan="3"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Alternative area for Buttons to add different types of rows Design inside row-->
              
  
            </div>
          </div>
        </form>
  
          <div class="auth-page-content bg-soft-primary">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-md-8 col-lg-6 col-xl-5">
                          <div class="card mt-4">
                              <div class="card ribbon-box border shadow-none mb-lg-0 right" *ngIf="isOrderReceived || isOrderApproved || isOrderCancelled">
                                <div class="card-body text-muted">
                                  <h5 class="fs-14 text-start mb-3">Approval Status:</h5>  
                                  <span 
                                    class="ribbon-three ribbon-three-primary"
                                    [ngClass]="{
                                      'ribbon-three-warning': isOrderReceived,
                                      'ribbon-three-success': isOrderApproved,
                                      'ribbon-three-danger': isOrderCancelled
                                    }">
                                      <span>
                                          {{ getApprovalStatus(orderState[present_state].name) }}
                                      </span>
                                  </span>
                                </div>
                              </div>
                            
                              <div class="card-body p-4 text-center">
                                  <form [formGroup]="quoteForm">
                                        <input type="hidden" id="id-field" />
                                        <input type="hidden" name="id" value="" formControlName="id" />
                                     
                                        <input type="hidden" formControlName="user_id"/>
                                        <input type="hidden" formControlName="organisation_id" />
                                        <input type="hidden" formControlName="quote_id" />
                                      <div class="mb-2">
                                        
                                          <label for="delivery_address" class="form-label">Delivery Address</label>
                                          <div class="form-icon left">
                                              <input type="text" id="delivery_address-field"
                                              [readonly]="isDisabled" 
                                              [class.border-opacity-50]="!isAccepted || !isDeclined"
                                              [class.border-0]="isAccepted || isDeclined"
                                              class="form-control form-control-icon bg-soft-light border-opacity-50" placeholder="Enter delivery address"
                                              formControlName="delivery_address" 
                                              ngx-google-places-autocomplete 
                                              [options]="options"
                                              (onAddressChange)="handleDeliveryAddressChange($event)"
                                              [ngClass]="{ 'is-invalid': submitted && quote_form['delivery_address'].errors}" />
                                              <i class="ri-map-pin-2-line"></i>
                                              <div *ngIf="submitted && quote_form['delivery_address'].errors" class="invalid-feedback"
                                              align="left">
                                              <div *ngIf="quote_form['delivery_address'].errors['required']">Delivery Address is required</div>
                                              </div>
                                          </div>
                                          
                                      </div>
                                  </form>
                                  <div class="avatar-lg mx-auto mt-2">
                                    <div class="avatar-title bg-light display-3 rounded-circle"
                                    [ngClass]="'text-'+getColor()"
                                    >
                                      <i [ngClass]="getIconClass()"></i>
                                    </div>
                                  </div>
                                
                                  <div class="mt-4 pt-2">
                                      <h4> {{getTitle()}} </h4>
                                      <p class="text-muted fs-5 mx-4">
                                          {{getSubTitle()}}
                                        <span [ngClass]="'text-'+getColor()">
                                          {{getConculsionText()}}
                                        </span>
                                      </p>
                                      <div class="mt-4 d-flex flex-shrink-0 gap-2" *ngIf="isAccepted || !isDeclined">
                                          <button 
                                          [ngClass]="'btn-'+getColor()"
                                          [disabled]="isDisabled" 
                                          type="submit" 
                                          class="btn w-100" 
                                          id="add-btn">
                                          {{ isAccepted ? 'Already Accepted' : 'Accept The Quote' }}
                                          </button>
                                      </div>
                                      <div class="mt-4 d-flex flex-shrink-0 gap-2" *ngIf="!isAccepted">
                                          <button type="button"
                                          (click)="confirm(declineModel,quoteId)" 
                                          [disabled]="isDisabled" 
                                          class="btn btn-danger w-100" id="delete-btn">
                                            {{ isDeclined ? 'Already Declined' : 'Decline The Quote' }}
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      
      </div>
    </div>
  </form>

  <div class="row justify-content-center pb-4" *ngIf="tokenExpired">
    <div class="col-xxl-9">
      <div class="auth-page-content bg-soft-primary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card mt-4">
                        <div class="card-body p-4 text-center">
                            <div class="avatar-lg mx-auto mt-2">
                              <div class="avatar-title bg-light display-3 rounded-circle text-warning">
                                <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json" trigger="loop" colors="primary:#e8bc52,secondary:#405189" style="width:120px;height:120px"></lord-icon>
                              </div>
                            </div>
                          
                            <div class="mt-4 pt-2">
                                <h4> Quote Access</h4>
                                <p class="text-muted fs-5 mx-4">
                                  Unfortunately, your access token for viewing the quote has expired. Kindly reach out to your quotation provider for further assistance.
                                </p>
                                <span class="fw-semibold">Expired Token ID:
                                  <small class="text-muted mx-4">{{token}}</small>
                                </span>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  </div>
  
  <!-- removeItemModal -->
  <ng-template #declineModel let-modal>
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="deleteRecord-close"
            (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
          <div class="mt-2 text-center">
            <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json" trigger="loop"
              colors="primary:#405189,secondary:#f06548" style="width:90px;height:90px"></lord-icon>
            <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>You are about to decline a quote?</h4>
              <p class="text-muted mx-4 mb-0">Declining your quote will disable your quotation and you can't make any decission on behalf of this quotation. For a new order, please request a fresh quotation.</p>
            </div>
          </div>
          <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal"
              (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
            <button type="button" class="btn w-sm btn-danger " id="delete-product" (click)="declineQuote(declineId)  "
              (click)="modal.close('Close click')">Yes, Decline It!</button>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </ng-template>