import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss'],
  providers:[DatePipe]
})
export class SessionTimeoutModalComponent {
  countdown: number = 60; // countdown as 60 seconds
  countdownInterval: any;
  countdownTimer: any = this.countdown * 1000;
  
  currentTime:any;
  expiryTime:any;
  constructor(public activeModal: NgbActiveModal, public authService: AuthenticationService, public sessionService: SessionService, public datePipe: DatePipe) {
    this.currentTime = Date.now();
    this.expiryTime = this.sessionService.expiryTimestamp + this.countdownTimer;
    // const currentTimeFormatted = this.datePipe.transform(this.currentTime, 'medium');
    // const expiryTimeFormatted = this.datePipe.transform(this.expiryTime, 'medium');
   }

  ngOnInit(): void {
    if(this.expiryTime > this.currentTime){
      this.countdownInterval = setInterval(() => {
        this.currentTime += 1000;
        this.countdown--;
        if (this.currentTime >= this.expiryTime) {
          this.logout();
        }
        // if (this.countdown === 0) {
        //   this.logout();
        // }
      }, 1000);
    } else if(this.expiryTime < this.currentTime){
      this.logout();
    }
    

  }

  extendSession(): void {
    this.activeModal.close('extend');
    clearInterval(this.countdownInterval);
    this.sessionService.extendSession();
  }

  logout(): void {
    this.sessionService.isSessionActive = false;
    this.sessionService.isSessionLogout = true;

    this.activeModal.close('logout');   
    localStorage.setItem('sessionTimeout', 'true');
    clearInterval(this.countdownInterval);
    this.sessionService.extendSession();
    this.authService.logout();
  }

  ngOnDestroy (): void {
    clearInterval(this.countdownInterval);
  }
}