import { MenuItem } from './menu.model';
export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'ri-dashboard-2-line',
    link: '/',
     
  },
  {
    id: 3,
    label: 'MENUITEMS.CRM.TEXT',
    subItems: [
      {
        id: 4,
        label: 'MENUITEMS.CRM.LIST.COMPANIES',
        link: '/companies',
        parentId: 3
      },
      {
        id: 5,
        label: 'MENUITEMS.CRM.LIST.CONTACTS',
        link: '/contacts',
        parentId: 3
      },
    ]
   },
   {
    id: 6,
    label: 'MENUITEMS.COMMUNICATIONS.TEXT',
    subItems: [
      {
        id: 7,
        label: 'MENUITEMS.COMMUNICATIONS.LIST.CHAT',
        link: '/chat',
        parentId: 6
      },
      {
        id: 8,
        label: 'MENUITEMS.COMMUNICATIONS.LIST.EMAIL',
        link: '/email',
        parentId: 6
      },
    ]
   },
   {
    id: 9,
    label: 'MENUITEMS.COMMERCE.TEXT',
    subItems: [
      {
        id: 10,
        label: 'MENUITEMS.COMMERCE.LIST.QUOTES',
        link: '/quotes',
        parentId: 9
      },
      {
        id: 11,
        label: 'MENUITEMS.COMMERCE.LIST.SALES',
        link: '/sales',
        parentId: 9
      },
      {
        id: 12,
        label: 'MENUITEMS.COMMERCE.LIST.PURCHASES',
        link: '/purchases',
        parentId: 9
      },
      {
        id: 13,
        label: 'MENUITEMS.COMMERCE.LIST.SUPPLIERS',
        link: '/suppliers',
        parentId: 9
      },
      {
        id: 14,
        label: 'MENUITEMS.COMMERCE.LIST.PRODUCTS',
        link: '/products',
        parentId: 9
      },
      {
        id: 15,
        label: 'MENUITEMS.COMMERCE.LIST.SERVICES',
        link: '/services',
        parentId: 9
      },
    ]
   },
   {
    id: 16,
    label: 'MENUITEMS.ACCOUNTS.TEXT',
    subItems: [
      {
        id: 17,
        label: 'MENUITEMS.ACCOUNTS.LIST.ACCOUNTSLIST',
        link: '/accounts',
        parentId: 16
      },
    ]
   },
   {
    id: 18,
    label: 'MENUITEMS.SALESMARKETING.TEXT',
    subItems: [
      {
        id: 19,
        label: 'MENUITEMS.SALESMARKETING.LIST.SALESMARKETINGLIST',
        link: '/salesmarketing',
        parentId:18
      },
    ]
   },
   {
    id: 20,
    label: 'MENUITEMS.HUMANRESOURCE.TEXT',
    subItems: [
      {
        id: 21,
        label: 'MENUITEMS.HUMANRESOURCE.LIST.HUMANRESOURCELIST',
        link: '/humanresource',
        parentId:20
      },
    ]
   },
   {
    id: 22,
    label: 'MENUITEMS.DOCUMENTS.TEXT',
    subItems: [
      {
        id: 23,
        label: 'MENUITEMS.DOCUMENTS.LIST.DOCUMENTSLIST',
        link: '/documents',
        parentId:22
      },
    ]
   },
   {
    id: 24,
    label: 'MENUITEMS.SETTINGS.TEXT',
    subItems: [
      {
        id: 25,
        label: 'MENUITEMS.SETTINGS.LIST.ORGANISATION',
        link: '/organisation',
        parentId:24
      },
      {
        id: 26,
        label: 'MENUITEMS.SETTINGS.LIST.USERACCOUNTS',
        link: '/useraccounts',
        parentId:24
      },
      {
        id: 27,
        label: 'MENUITEMS.SETTINGS.LIST.INTEGRATIONS',
        link: '/integrations',
        parentId:24
      },
      {
        id: 28,
        label: 'MENUITEMS.SETTINGS.LIST.MODULES',
        link: '/modules',
        parentId:24
      },
    ]
   },
   {
    id: 29,
    label: 'MENUITEMS.SUBSCRIPTION.TEXT',
    subItems: [
      {
        id: 30,
        label: 'MENUITEMS.SUBSCRIPTION.LIST.SUBSCRIPTIONLIST',
        link: '/subscription',
        parentId:29
      },
    ]
   },
]; 