import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = `${environment.API_ENDPOINT}/commerce-company`;
const baseApiUrl = `${environment.API_ENDPOINT}`;

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(private http: HttpClient) {

  }

  //Get Quote Token Details
  getQuoteTokenData(token:any): Observable<any> {
    return this.http.get(`${baseApiUrl}/quote-view/quote-token/details/${token}`, {});
  }
  
  // Single
  getSingleQuoteData(id: any): Observable<any> {
    return this.http.get<any>(`${baseApiUrl}/quote-view/quote/details/${id}`, {});
  }

  // Decline Quote
  patchQuoteState(quote:any, id: any): Observable<any> {
    return this.http.put(`${baseApiUrl}/quote-view/quote/state/${id}`, quote);
  }

  // existing order
  orderExistByQuoteId(quoteId:any): Observable<any> {
    return this.http.get(`${baseApiUrl}/quote-view/order/byquote/${quoteId}`, {});
  }

  // get
  getQuoteWithOrderProductData(id: any): Observable<any> {
    return this.http.get<any>(`${baseApiUrl}/quote-view/order/details/${id}`, {});
  }

  /**
   * Commerce Product Rest Api
   */
  // Single
  getSingleProductData(id: any): Observable<any> {
    return this.http.get<any>(`${baseApiUrl}/quote-view/product/details/${id}`, {});
  }
  // Get
  getProductData(organisation_id:any): Observable<any> {
    return this.http.get(`${baseApiUrl}/quote-view/product/byorganisation/${organisation_id}`);
  }

  // POST
  postOrderData(order: any): Observable<any> {
    return this.http.post(baseApiUrl + '/quote-view/order', order);
  }

  //Get Organisation
  getOrganisation(user_id: number) {
    return this.http.get<any>(`${baseApiUrl}/quote-view/organisation/byuser/${user_id}`, {});
  }

  //Get company detail as organisation detail from Commerce Company settings 
  getCommerceCompany(organisation_id: number) {
    return this.http.get<any>(`${baseApiUrl}/quote-view/commerce-company/byorg/${organisation_id}`, {});
  }
  
}
