import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ProfileService } from 'src/app/evergrow/profile/profile.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})

/**
 * Lock Screen lock-screen Component
 */
export class LockScreenComponent implements OnInit {

  fallback_image_url: any = './assets/images/fallover/fallover-icon.png';

  // Login Form
  lockscreenForm!: UntypedFormGroup;
  submitted = false;
  fieldTextType!: boolean;
  error = '';
  returnUrl!: string;
  lockScreenId:number | null | undefined;

  userId:number;
    //API DATA
    profile: any;

  // set the current year
  year: number = new Date().getFullYear();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthenticationService,
    public service: ProfileService,
    private route: ActivatedRoute,
    ) { 
    this.userId = this.authService.getUserId();
    this.editDataGet(this.userId);
  }

  ngOnInit(): void {
    
    /**
     * Form Validatyion
     */
     this.lockscreenForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
  }

  editDataGet(id: any) {
    this.submitted = false;

    this.service.getProfile(id).subscribe(
      (data:any) => {
        this.profile = data;
        this.profile.fullName = data.first_name + " " + data.last_name
      },
      (error:any) => {
        console.log(error);
        // this.content = JSON.parse(err.error).message;
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.lockscreenForm.controls; }

  /**
   * Form submit
   */
   onSubmit() {
    
    // stop here if form is invalid
    if (this.lockscreenForm.invalid) {
      return;
    }else{
      // console.log(this.profile);
      this.authService.checkPasswordValid(this.profile.user_id, this.f['password'].value);
    }
  }

}
