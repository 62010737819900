import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

const apiUrl = `${environment.API_ENDPOINT}/commerce-company`;
const baseApiUrl = `${environment.API_ENDPOINT}`;

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(private http: HttpClient) {}

    //Upload Image
    uploadCommerceCompanyImage(userId: number, type: any, file: File, index: number): Observable<any> {
      const formData: FormData = new FormData();
      formData.append("file", file); // Append the file to the form data
      return this.http.post(`${apiUrl}/upload/${userId}/${type}/${index}`, formData);
    }
        // Delete CommerceCompany Logo
    deleteCommerceCompanyLogo(userId: number, type: any, index: number, filename: string, companyId: number): Observable<any> {
      return this.http.put(`${apiUrl}/deletelogo/${userId}/${type}/${index}/${filename}/${companyId}`, {});
    }


  getOrganisation(user_id: number) {
    return this.http.get<any>(
      `${baseApiUrl}/organisation/byuser/${user_id}`,
      {}
    );
  }
  getAccountOrganisation(org_id: number) {
    return this.http.get<any>(`${baseApiUrl}/organisation/${org_id}`, {});
  }

  /**
   * Commerce Company Services
   * @param organisation_id
   * @returns
   */
  getCommerceCompany(organisation_id: number) {
    return this.http.get<any>(`${apiUrl}/byorg/${organisation_id}`, {});
  }
  updateCommerceCompany(data: any) {
    console.log(data,"==========================upload commerce company")
    return this.http.put(`${apiUrl}/${data.id}`, data);
  }
  createCommerceCompany(data: any) {
    return this.http.post(`${apiUrl}`, data);
  }

  /**
   * Tax Services
   * @param data
   * @param id
   * @returns
   */
  patchTaxData(data: any, id: any) {
    return this.http.put(`${baseApiUrl}/tax/${id}`, data);
  }
  postTaxData(data: any) {
    return this.http.post(`${baseApiUrl}/tax`, data);
  }
  getSingleTaxData(id: any) {
    return this.http.get<any>(`${baseApiUrl}/tax/${id}`, {});
  }

  // updateTax(data: any) {
  //   return this.http.put(`${baseApiUrl}/tax/${data.id}`, data);
  // }
  // createTax(data: any) {
  //   return this.http.post(`${baseApiUrl}/tax`, data);
  // }
  deleteTax(id: any) {
    return this.http.delete<any>(`${baseApiUrl}/tax/${id}`);
  }
  getTaxList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/tax/list/${organisation_id}`,
      {}
    );
  }
  getDefaultList(organisation_id: number,region:any) {

      
    let params = new HttpParams();
    params = params.append("region", region);

    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/tax/default/list/${organisation_id}`, { params: params }
    );
  }

  /**
   * Notification Services
   * @param data
   * @returns
   */
  createNotifications(data: any) {
    return this.http.post(`${baseApiUrl}/notifications`, data);
  }
  updateNotifications(data: any) {
    return this.http.put(`${baseApiUrl}/notifications/${data.id}`, data);
  }
  getNotifications(organisation_id: number) {
    return this.http.get<any>(
      `${baseApiUrl}/notifications/byorg/${organisation_id}`,
      {}
    );
  }
  /**
   * Pricing Services
   * @param organisation_id
   * @returns
   */
  // updatePricing(data: any) {
  //   return this.http.put(`${baseApiUrl}/pricing/${data.id}`, data);
  // }
  // createPricing(data: any) {
  //   return this.http.post(`${baseApiUrl}/pricing`, data);
  // }

  patchPricingData(data: any, id: any) {
    return this.http.put(`${baseApiUrl}/pricing/${id}`, data);
  }
  postPricingData(data: any) {
    return this.http.post(`${baseApiUrl}/pricing`, data);
  }
  getSinglePricingData(id: any) {
    return this.http.get<any>(`${baseApiUrl}/pricing/${id}`, {});
  }
  deletePricing(id: any) {
    return this.http.delete<any>(`${baseApiUrl}/pricing/${id}`);
  }
  getPricingList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/pricing/list/${organisation_id}`,
      {}
    );
  }
  getPricingGroupTypeDefaultList(organisation_id: number, group_type: any) {
  
    let params = new HttpParams();
    params = params.append("group_type", group_type);
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/pricing/default/grouptype/list/${organisation_id}`,
      { params: params }
    );
  }

  /**
   * Payments Services
   * @param organisation_id
   * @returns
   */
  getPayments(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/payments/byorg/${organisation_id}`,
      {}
    );
  }
  updatePayments(data: any, id: any) {
    return this.http.put(`${baseApiUrl}/payments/${id}`, data);
  }
  createPayments(data: any) {
    return this.http.post(`${baseApiUrl}/payments`, data);
  }

  /**
   * Shipping Services
   * @param organisation_id
   * @returns
   */
  // updateShipping(data: any) {
  //   return this.http.put(`${baseApiUrl}/shipping/${data.id}`, data);
  // }
  // createShipping(data: any) {
  //   return this.http.post(`${baseApiUrl}/shipping`, data);
  // }

  patchShippingData(data: any, id: any) {
    return this.http.put(`${baseApiUrl}/shipping/${id}`, data);
  }
  postShippingData(data: any) {
    return this.http.post(`${baseApiUrl}/shipping`, data);
  }
  getSingleShippingData(id: any) {
    return this.http.get<any>(`${baseApiUrl}/shipping/${id}`, {});
  }
  deleteShipping(id: any) {
    return this.http.delete<any>(`${baseApiUrl}/shipping/${id}`);
  }
  getShippingList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/shipping/list/${organisation_id}`,
      {}
    );
  }
  getShippingTypeDefaultList(organisation_id: number, shipping_type: any) {
    // let params = new HttpParams();
    // params = params.append("shipping_type", shipping_type);
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/shipping/default/shippingtype/list/${organisation_id}`
    );
  }

  /**
   * Category Services
   * @param organisation_id
   * @returns
   */
  getCategoryList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/category/list/${organisation_id}`,
      {}
    );
  }
  updateCategory(data: any) {
    return this.http.put(`${baseApiUrl}/category/${data.id}`, data);
  }
  createCategory(data: any) {
    return this.http.post(`${baseApiUrl}/category`, data);
  }

  /**
   * Warehouse Services
   * @param data
   * @returns
   */
  // updateWarehouse(data: any) {
  //   return this.http.put(`${baseApiUrl}/warehouse/${data.id}`, data);
  // }
  // createWarehouse(data: any) {
  //   return this.http.post(`${baseApiUrl}/warehouse`, data);
  // }
  // deleteWarehouse(id: any) {
  //   return this.http.delete<any>(`${baseApiUrl}/warehouse/${id}`);
  // }
  // getWarehouseList(organisation_id: number) {
  //   return this.http.get<{ data: Array<any>, count: number }>(`${baseApiUrl}/warehouse/org/${organisation_id}`, {});
  // }

  patchWarehouseData(data: any, id: any) {
    return this.http.put(`${baseApiUrl}/warehouse/${id}`, data);
  }
  postWarehouseData(data: any) {
    return this.http.post(`${baseApiUrl}/warehouse`, data);
  }
  getSingleWarehouseData(id: any) {
    return this.http.get<any>(`${baseApiUrl}/warehouse/${id}`, {});
  }
  deleteWarehouse(id: any) {
    return this.http.delete<any>(`${baseApiUrl}/warehouse/${id}`);
  }
  getWarehouseList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/warehouse/list/${organisation_id}`,
      {}
    );
  }
  getWarehouseTypeDefaultList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/warehouse/default/list/${organisation_id}`,
      {}
    );
  }

  /**
   * Numbering Services
   * @param organisation_id
   * @returns
   */
  getNumberingList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/numbering/list/${organisation_id}`,
      {}
    );
  }
  updateNumbering(data: any) {
    return this.http.put(`${baseApiUrl}/numbering/${data.id}`, data);
  }
  createNumbering(data: any) {
    return this.http.post(`${baseApiUrl}/numbering`, data);
  }

  /**
   * Policies Services
   * @param organisation_id
   * @returns
   */
  getPolicyList(organisation_id: number) {
    return this.http.get<{ data: Array<any>; count: number }>(
      `${baseApiUrl}/policy/list/${organisation_id}`,
      {}
    );
  }
  updatePolicy(data: any) {
    return this.http.put(`${baseApiUrl}/policy/${data.id}`, data);
  }
  createPolicy(data: any) {
    return this.http.post(`${baseApiUrl}/policy`, data);
  }
}
