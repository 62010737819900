import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { ToastrService } from "ngx-toastr";
import { getFirebaseBackend } from "../../authUtils";
import { User } from "../models/auth.models";

import { map } from "rxjs/operators";
import {
  BehaviorSubject,
  Observable,
  catchError,
  throwError as ObservableThrowError,
} from "rxjs";
import { GlobalComponent } from "../../global-component";
import { CookieService } from "ngx-cookie-service";
import { SessionService } from "./session.service";
import { PERMISSIONS } from "src/app/evergrow/constants/app.constants";
import { environment } from "src/environments/environment";
// import { showToastNoTimer } from 'src/app/shared/highlight-row/highlight-row';
import { HandlingError } from "src/app/shared/handling-error/handling-error";

const AUTH_API = GlobalComponent.AUTH_API;
const baseApiUrl = `${environment.API_ENDPOINT}`;
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({ providedIn: "root" })

/**
 * Auth-service Component
 */
export class AuthenticationService {
  public showLoader: boolean = false;
  user!: User;
  error = "";

  userId!: number;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUserObj: Observable<User>;

  private currentOrganisationIdSubject: BehaviorSubject<string>;
  public currentOrganisationIdObj: Observable<string>;

  private currentOrganisationSubject: BehaviorSubject<string>;
  public currentOrganisationObj: Observable<string>;

  constructor(
    private http: HttpClient,
    public toster: ToastrService,
    public router: Router,
    public cookieService: CookieService,
    public sessionService: SessionService,
    private handlingError: HandlingError,
    private location: Location
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUserObj = this.currentUserSubject.asObservable();

    this.currentOrganisationIdSubject = new BehaviorSubject<string>(
      localStorage.getItem("organisationId") || ""
    );
    this.currentOrganisationIdObj =
      this.currentOrganisationIdSubject.asObservable();

    this.currentOrganisationSubject = new BehaviorSubject<string>(
      localStorage.getItem("currentOrganisation") || ""
    );
    this.currentOrganisationObj =
      this.currentOrganisationSubject.asObservable();
  }

  /**
   * current user
   */
  public get currentUserValue(): User {
    return (
      this.currentUserSubject.value ||
      JSON.parse(localStorage.getItem("currentUser")!)
    );
  }

  /**
   * current organisation id
   */
  public get currentOrganisationIdValue(): string {
    return this.currentOrganisationIdSubject.value;
  }

  public get currentOrganisationValue(): string {
    return (
      this.currentOrganisationSubject.value ||
      JSON.parse(localStorage.getItem("currentOrganisation")!)
    );
  }

  /**
   *
   * @param organisationId
   */
  public setOrganisationId(organisationId: string): void {
    localStorage.setItem("organisationId", organisationId);
    this.currentOrganisationIdSubject.next(organisationId);
  }

  /**
   *
   * @param organisationData
   */
  public setCurentOrganisation(organisationData: any): void {
    // localStorage.setItem('currentOrganisation', organisationData);
    localStorage.setItem(
      "currentOrganisation",
      JSON.stringify(organisationData)
    );

    this.currentOrganisationSubject.next(organisationData);
  }

  /**
   * Performs the register
   */
  register(req: User) {
    this.showLoader = true;

    // Register Api
    return this.http.post(AUTH_API + "signup", req, httpOptions).subscribe(
      (data: any) => {
        this.showLoader = false;
        this.router.navigate(["/auth/success"], {
          queryParams: { msg: "registration" },
        });
        this.cookieService.set("success", "true", 1, "/");
      },
      (error) => {
        this.showLoader = false;
        this.handlingError.handleAccountError(error);
      }
    );
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return this.http
      .post(
        AUTH_API + "signin",
        {
          username: email,
          password,
        },
        httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.state == 1) {
            this.sessionService.isSessionActive = true; //making session activity service active as true after logged in for users
            localStorage.setItem("toast", "true");
            localStorage.setItem("currentUser", JSON.stringify(res));
            localStorage.setItem("token", res.accessToken);
            this.router.navigate(["/"]);
            this.toster.success("Login Successfull!");
          } else {
            // this.toster.warning('Your username is inactive');
            this.cookieService.set("error", "Inactive", 1, "/");
            this.router.navigate(["/auth/success"], {
              queryParams: { msg: "signin" },
            });
          }
        },
        (error) => {
          this.handlingError.handleAccountError(error);
        }
      );
  }

  /**
   * Performs the auth to check password
   * @param id id of user
   * @param password password of user
   */
  checkPasswordValid(id: string, password: string) {
    return this.http
      .post(
        AUTH_API + "checkpwd",
        {
          id: id,
          password,
        },
        httpOptions
      )
      .subscribe(
        (res: any) => {
          this.location.back();
          this.toster.success("Unlock Successfull!");
        },
        (error) => {
          this.handlingError.handleAccountError(error);
        }
      );
  }

  /**
   * Performs the lock screen creating operation
   */
  createLockScreen(userId: number, deviceInfo: string) {
    return this.http
      .post(
        AUTH_API + "lockscreen",
        {
          user_id: userId,
          device_info: deviceInfo,
        },
        httpOptions
      )
      .subscribe(
        (res: any) => {
          this.router.navigate(["/lock-screen"]);
          this.toster.success("User Lockedin Successfully!");
        },
        (error) => {
          this.handlingError.handleAccountError(error);
        }
      );
  }

  public isUserLocked(): any {
    const user_id = this.getUserId();
    return this.http.get(AUTH_API + `islocked/${user_id}`, httpOptions);
  }

  /**
   * Returns the current user
   */
  public currentUser(): any {
    return getFirebaseBackend()!.getAuthenticatedUser();
  }

  /**
   * Returns the current userId
   */
  public getUserId(): any {
    var userStr = localStorage.getItem("currentUser") || "";
    return JSON.parse(userStr).id;
  }

  public getUserRole(): any {
    var userStr = localStorage.getItem("currentUser") || "";
    return JSON.parse(userStr).roleId;
  }

  public getUserRoleName(): any {
    var userStr = localStorage.getItem("currentUser") || "";
    return JSON.parse(userStr).role;
  }

  public getOrganisationId() {
    this.userId = this.getUserId();
    this.http
      .get<any>(`${baseApiUrl}/organisation/byuser/${this.userId}`, {})
      .subscribe(
        (data: any) => {
          if (data?.id) {
            this.setOrganisationId(data.id);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    var organisationId = localStorage.getItem("organisationId") || "";
    return organisationId;
  }

  public currentOrganisation() {
    this.userId = this.getUserId();
    this.http
      .get<any>(`${baseApiUrl}/organisation/byuser/${this.userId}`, {})
      .subscribe(
        (data: any) => {
          if (data) {
            this.setCurentOrganisation(data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    var currentOrganisation = localStorage.getItem("currentOrganisation") || "";
    var currentOrg = JSON.parse(currentOrganisation);

    return currentOrg;
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    this.sessionService.isSessionActive = false;
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    this.currentUserSubject.next(null!);
    this.toster.info("Logout Successfully!");
    this.router.navigate(["/auth/login"]);
  }

  /**
   * Forgot password
   * @param username email
   */
  forgotPassword(username: string) {
    this.showLoader = true;
    return this.http
      .post(
        AUTH_API + "forgotpwd",
        {
          username,
        },
        httpOptions
      )
      .subscribe(
        (data: any) => {
          this.showLoader = false;
          this.router.navigate(["/auth/success"], {
            queryParams: { msg: "forgotpassword" },
          });
          this.cookieService.set("success", "true", 1, "/");
        },
        (error) => {
          this.handlingError.handleAccountError(error);
        }
      );
  }

  /**
   * Reset password
   * @param new_password string
   * @param token string
   */
  resetPassword(new_password: string, token: string) {
    this.showLoader = true;
    return this.http
      .post(
        AUTH_API + "resetpwd",
        {
          new_password,
          token,
        },
        httpOptions
      )
      .subscribe(
        (data: any) => {
          this.showLoader = false;
          this.router.navigate(["/auth/success"], {
            queryParams: { msg: "resetpassword" },
          });
          this.cookieService.set("success", "true", 1, "/");
        },
        (error) => {
          this.showLoader = false;
          this.handlingError.handleAccountError(error);
        }
      );
  }

  /**
   * Reset password
   * @param new_password string
   * @param token string
   */
  activateAccount(token: string, state: number) {
    this.showLoader = true;
    return this.http
      .post(
        AUTH_API + "activateaccount",
        {
          token,
          state,
        },
        httpOptions
      )
      .subscribe(
        (data: any) => {
          this.showLoader = false;
          this.router.navigate(["/auth/success"], {
            queryParams: { msg: "activation" },
          });
          this.cookieService.set("success", "true", 1, "/");
        },
        (error) => {
          this.showLoader = false;
          this.handlingError.handleAccountError(error);
        }
      );
  }
  activateSetpwd(new_password: string, token: string) {
    this.showLoader = true;
    return this.http
      .post(
        AUTH_API + "activateSetPwd",
        {
          new_password,
          token,
        },
        httpOptions
      )
      .subscribe(
        (data: any) => {
          this.showLoader = false;
          this.router.navigate(["/auth/success"], {
            queryParams: { msg: "activation" },
          });
          this.cookieService.set("success", "true", 1, "/");
        },
        (error) => {
          this.showLoader = false;
          this.handlingError.handleAccountError(error);
        }
      );
  }

  private updateSession(user: User) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // user.role = "ADMIN";
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
    //location.reload();
  }
  updatePermission(permission: any) {
    const session = this.currentUserValue;
    this.updateSession({
      ...this.currentUserValue,
      permission: permission || session.permission,
    });
  }
  hasAccess(mId: number, pKey: string) {
    //console.log(this.currentUserValue);
    const roleId: any = this.currentUserValue?.roleId || 0;
    const map: any = (this.currentUserValue || { permission: {} }).permission;
    const mPermissions = (map[roleId] || {})[mId] || {};
    return pKey == PERMISSIONS.ANY
      ? Object.keys(mPermissions).length > 0
      : (mPermissions[pKey] || 0) != 0;
  }
  hasWriteAccess(mId: number) {
    return this.hasAccess(mId, PERMISSIONS.WRITE);
  }
}
