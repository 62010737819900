import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, FormArray, Validators, AbstractControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { DatePipe, DecimalPipe } from '@angular/common';
import { removeHighlight, showToast, showToastWithHighlightRow } from 'src/app/shared/highlight-row/highlight-row';

import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GoogleMapService } from 'src/app/shared/googleAddress/google-map-service';
import { ORDER_STATE, QUOTE_TEMPLATE_STATE, quoteTemplateStateMap, orderStateMap } from 'src/app/evergrow/constants/app.constants';

import { replacePlaceHolders } from 'src/app/shared/replacePlaceHolders/replace-placeholders';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QuotesService } from 'src/app/core/services/quotes.service';
import { SettingsService } from 'src/app/evergrow/commerce/commerce-settings/settings.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent {
  
  breadCrumbItems!: Array<{}>;
  // Flag to show/hide the design selector
  showDesignSelector: boolean = true;
  
  //Initialize Google Address API
  googleMapService: GoogleMapService = new GoogleMapService();
  isMapLoaded = false;
  options: any = {
    componentRestrictions: { country: 'AU' }
  }

  datePipe = new DatePipe('en-US');

  public quoteDetails?: any;
  public quoteSummaryDetails?: any;

  currentOrganisation:any;
  public organisationId:any;
  public userId:any;
  token: any;
  quoteId:any;
  orderId:any;
  isView: boolean = false;
  
  paymentSign = "$";
  sub_total = 0;
  tax_rate_percent = 0;
  shippingRate = 0;
  discountRate = 0;

  quoteForm!: UntypedFormGroup;
  quoteRowForm!: UntypedFormGroup;

  
  imageURL: string | undefined;
  isImgUpload: boolean = false;
  fallback_image_url: any = './assets/images/fallover/fallover-background.png';
  inputFormValues!: any;

  submitted: boolean = false;
  isEdit: boolean = false;

  present_state: number= 0;
  state_query = quoteTemplateStateMap;

  //QUOTATION Action
  isAccepted: boolean = false;
  isDeclined: boolean = false;
  existingOrder:any;
  //make disable or redable only
  isDisabled: boolean = false;
  // ProductDetails on Product Id
  productDetails: any = {};
  //ORDER Action
  orderState = orderStateMap;
  isOrderReceived: boolean = false; //it is same as isAccepted
  isOrderApproved: boolean = false;
  isOrderCancelled: boolean = false;
  //API DATA
  product_lists: any;
  product!: any;
  contact?: any;
  company?: any;
  econtent: any;
  filteredContacts: any[] = [];
   
  //Your Company Details
  company_name:any; //Company Name
  trading_name:any; //Trading Name
  abn:any; //ABN
  acn:any; //ACN
  phone:any; //Company Phone
  fax:any; //Company Fax
  email:any; //Company Email
  primary_address:any; //Company Primary Address

  //Customer organisation Details
  recipient_company_name:any; //Recipient Company Name 
  recipient_email:any; // Recipient Email
  recipient_phone:any; // Recipient Phone
  recipient_delivery_address:any; // Recipient Delivery Address

  quote_title:any; //Quote Title
  quote_number:any; //Quote Number
  created_date:any; //Created Date
  expiry_date:any; //Expiry Date
  order_total:any; //Order Total
  delivery_address:any; //Delivery Address
  discount:any; //Discount
  tokenExpired:boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public quotesService: QuotesService,
    public commerceSettingService: SettingsService,
    private toster: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {

    this.token = this.route.snapshot.paramMap.get("token");

    this.quotesService.getQuoteTokenData(this.token).subscribe(
      (data) => {
        console.log("Quote Data:", data)
        this.tokenExpired = false;
        this.quoteId = data.quote_id;
        this.userId = data.user_id;

        this.quoteForm.controls['quote_id'].setValue(data.quote_id);
        this.quoteForm.controls['user_id'].setValue(data.user_id);
        
        this.quotesService.getOrganisation(this.userId).subscribe(data => {
          this.organisationId = data.id;
          this.quoteForm.controls['organisation_id'].setValue(data.id);
          this.getCommerceCompanyData(this.organisationId);
          this.getCommerceSettingsData(this.organisationId);
        });
        
        this.getQuoteData(this.quoteId);
        this.quotesService.orderExistByQuoteId(this.quoteId).subscribe(
          (data) =>{
            console.log("Order Data:", data)
            this.existingOrder = data;
            this.orderId = data.id;
            this.loadExistingOrder(this.orderId);
          },
          (error) => {
            console.log("There are Not any order so load Quote data");
            this.isView= !!this.quoteId;
            this.loadQuoteData(this.quoteId);
          }
        )
      },
      (error) => {
        this.tokenExpired = true;
      });

  }

  ngOnInit(): void {
    /**
    * BreadCrumb
    */
    this.breadCrumbItems = [
      { label: 'Commerce' },
      { label: 'Quotes', active: true }
    ];

    this.googleMapService.initGoogleMap();

    this.quoteForm = this.formBuilder.group({
      id: [''],
      user_id: [''],
      organisation_id: [''],
      quote_id: [''],
      delivery_address:[''],
    });

    this.quoteRowForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });

    console.log("CONTROLS DATA: ", this.rows.controls);
  }

  getQuoteData(quoteID:any){
    this.quotesService.getSingleQuoteData(quoteID).subscribe(
      (data: any) => {
        console.log("Quote Details:", data)
        //Customer Details Reciever Details
        this.recipient_company_name = data.recipient_company_name;//data.company_id;// Recipient Company Name //get if from company_id
        this.recipient_email = data.recipient_email;// Recipient Email
        this.recipient_phone = data.recipient_phone;// Recipient Phone
        this.recipient_delivery_address = data.recipient_delivery_address;// Recipient Delivery Address

        //Quotation Details
        this.quote_title = data.name; //Quote Title
        this.quote_number = "#"+data.quote_number; //Quote Number
        this.created_date = this.datePipe.transform(data.created_at, 'longDate'); //Created Date
        this.expiry_date = this.datePipe.transform(data.expiry_date, 'longDate'); //Expiry Date
        this.delivery_address = data.recipient_delivery_address; //Delivery Address
        for (let row of data.rows) {
          if(row.row_type === 'productSummary'){
            this.order_total = "$"+row.total_amount; //Order Total
            this.discount = "$"+row.discount_rate; //Discount
          }
        }
        
      });
  }
  getCommerceCompanyData(orgID:any) {
    this.quotesService.getCommerceCompany(orgID).subscribe(data => {
      //Company Details on the basis of Commerce Company Settings but should be from company details
      this.company_name = data.company_name; //Company Name
      this.trading_name = data.trading_name; //Trading Name
      this.abn = data.abn; //ABN
      this.acn = data.acn; //ACN
      this.phone = data.phone; //Company Phone
      this.fax = data.fax; //Company Fax
      this.email = data.email; //Company Email
      this.primary_address = data.primary_address; //Company Primary Address
    });
  }
  
  getCommerceSettingsData(orgID:any){
    this.commerceSettingService.getTaxList(orgID).subscribe(res => {
      var tax_rate = res.data.filter(taxList => taxList.region === 'Australia');
    
      this.tax_rate_percent = tax_rate[0].tax_percentage /100;
    });

    this.commerceSettingService.getShippingList(this.organisationId).subscribe(res => {
      var shipping = res.data.filter(shippingList => shippingList.shipping_type === 'Standard');
     
      this.shippingRate = shipping[0].rate;
    });

    this.commerceSettingService.getPricingList(this.organisationId).subscribe(res => {
      var discount = res.data.filter(pricingList => pricingList.group_type === 'Discount');

      this.discountRate = discount[0].percentage / 100;
    });
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     if (this.isEdit) {
  //       this.googleMapService.initGoogleMap();
  //       // this.loadQuoteData();
  //     }
  //   }, 0);
  // }

  /**
   * get Form Datas
   */
  get quote_form() {
    return this.quoteForm.controls;
  }

  get rows(): FormArray {
    return this.quoteRowForm.get('rows') as FormArray;
  }
  
  toggleRecurring(e: any, row: any, index?:any) {
    
    e.target.checked? row.controls['recurring'].setValue(1) : row.controls['recurring'].setValue(0);
    
    if(row.controls['recurring'].value == 0) {
      row.controls['recurring_time'].setValue(null);
      row.controls['recurring_cycle'].setValue(null);
    }

  }

  popProductDiscountPrice(product: any, index:number) {

    if(product.controls){
      
      var spanElement = document.getElementById('discount_' + index);
      if (spanElement) {
        spanElement.textContent = this.paymentSign;
        spanElement.style.display = 'inline';
      }
    }else {
      if(product.discount){
        var spanElement = document.getElementById('discount_' + index);
      }
    
    }
    
  }

  getProductById(productId: number): any {
    if (!this.productDetails[productId]) {
      this.quotesService.getSingleProductData(productId).subscribe(
        (data) => {
          this.productDetails[productId] = data;
        },
        (error) => {
          return "The product is invalid";
        }
      );
    }
    return true;    
  }

  getProductAfterTaxAmount(productQuantity:any, productRate:any, taxableRate:any = 0) {
    let totalAmount = productQuantity * productRate;
    // return parseFloat((totalAmount+(totalAmount*(taxableRate/100))).toFixed(2));
    return parseFloat((totalAmount).toFixed(2));
  }

  getProductArray(row: any): FormArray {
    this.updateProductSummary();
    return row.get('products') as FormArray;
  }

  /**
   * Calculate the each row with product table having each item calculating 
   * its total amount on each row with its rate and quantity. including tax and discount
   * @param row 
   */
  calculateProductAmount(row: any): void {

    const product_quantity = row.get('product_quantity').value;
    const product_rate = row.get('product_rate').value;
    
    // var productAmount = parseFloat((product_rate * product_quantity).toFixed(2));

    var productAmountBeforeTax = parseFloat((product_rate * product_quantity).toFixed(2)); // Net amount
    var productAmount = productAmountBeforeTax; // Net amount
    
    // //Taxable If selected Included or Excluded
    // if(row.get('taxable_rate').value > 0){
    //   productAmount = productAmountBeforeTax + (productAmountBeforeTax * (row.get('taxable_rate').value/100));// product item after tax
    // }else{
    //   productAmount = productAmountBeforeTax;// product item before tax
    // }
  
    //After Tax Discountable If selected Percentage or Fixed
    var total_discount_amount = 0;
    if(row.get('discount_type').value == "Percentage"){
      total_discount_amount = ((productAmount * row.get('discount_value').value)/100);
    }else{
      total_discount_amount = row.get('discount_value').value;
    }
    
    var total_amount_price = parseFloat((productAmount - total_discount_amount).toFixed(2));
    
    row.get('product_amount').setValue(total_amount_price);

    
    this.updateProductSummary();
  }

  updateProductSummary(): void {
    const subtotalWithTaxDiscount = this.calculateSubtotalWithTaxDiscount();//parseFloat(this.calculateSubtotalWithTaxDiscount().toFixed(2));
    // const tax_rate = parseFloat((sub_total * this.tax_rate_percent).toFixed(2));
    // const discount_rate = parseFloat((sub_total * this.discountRate).toFixed(2));
    // const total_amount = parseFloat((sub_total + tax_rate - discount_rate + this.shippingRate).toFixed(2));
        
    const productSummaryRow = this.rows.controls.find(row => row.value.row_type === 'productSummary');
    if (productSummaryRow) {

      let shippingCharge = productSummaryRow.get('shipping_rate')?.value;

      const total_amount = subtotalWithTaxDiscount + shippingCharge;//parseFloat((subtotalWithTaxDiscount + shippingCharge).toFixed(2));
      // row.get('product_amount').setValue(sub_total);
      // productSummaryRow.get('sub_total')?.setValue(sub_total);
      // productSummaryRow.get('tax_rate')?.setValue(tax_rate);
      // productSummaryRow.get('discount_rate')?.setValue(discount_rate);
      // productSummaryRow.get('shipping_rate')?.setValue(parseFloat(this.shippingRate.toFixed(2)));
      productSummaryRow.get('total_amount')?.setValue(parseFloat(total_amount).toFixed(2));
    }
  }

  calculateSubtotalWithTaxDiscount(): number {
    
    let subtotalAmount = 0;

    let totalTaxIncludedAmount = 0;
    let totalTaxIncluded = 0;

    let totalTaxExcludedAmount = 0;
    let totalTaxExcluded = 0;

    let totalDiscountAmount = 0;

    

    const subtotalWithTaxDiscount = parseFloat(this.rows.controls.reduce((acc, row) => {
        if (row.value.row_type === 'productTable') {
            const products = row.get('products') as FormArray;
            const tableTotal = products.controls.reduce((tableAcc, product) => {

                let productTaxedAmount = 0;
                let productTax = 0;
                let productDiscount = 0;

                let productAmountBeforeTax = product.get('product_quantity')?.value * product.get('product_rate')?.value; // Net amount
                
                let isTaxIncluded =  parseInt(row.value.tax_column);//product.get('tax_column').value; // Check if tax is included

                if(isTaxIncluded === 1){
                  productTax = ((productAmountBeforeTax/(1+(product.get('taxable_rate')?.value/100))) * (product.get('taxable_rate')?.value/100));//(productAmountBeforeTax * (product.get('taxable_rate')?.value/100))
                  productTaxedAmount = productAmountBeforeTax;// + productTax;

                  if(product.get('discount_value')?.value > 0){
                    if(product.get('discount_type')?.value =='Percentage'){
                      productDiscount  = productTaxedAmount * (product.get('discount_value')?.value/100);
                      totalDiscountAmount += productDiscount;
                    } else if(product.get('discount_type')?.value =='Fixed'){
                      productDiscount = parseInt(product.get('discount_value')?.value);
                      totalDiscountAmount += productDiscount;
                    }
                  }

                  let productAmount = product.get('product_amount')?.value; // Net amount

                  totalTaxIncludedAmount += productTaxedAmount;//productAmount;
                  totalTaxIncluded += productTax;
                  subtotalAmount += productAmountBeforeTax;
                  return tableAcc + productAmount;

                }else{
                  // productTax = (productAmountBeforeTax * (this.tax_rate_percent));
                  productTaxedAmount = productAmountBeforeTax;

                  if(product.get('discount_value')?.value > 0){
                    if(product.get('discount_type')?.value =='Percentage'){
                      productDiscount = productTaxedAmount * (product.get('discount_value')?.value/100);
                      totalDiscountAmount += productDiscount;
                    } else if(product.get('discount_type')?.value =='Fixed'){
                      productDiscount = parseInt(product.get('discount_value')?.value);
                      totalDiscountAmount += productDiscount;
                    }
                  }

                  let productAmount = product.get('product_amount')?.value; // Net amount

                  productTax = (productTaxedAmount * (this.tax_rate_percent)); //(productAmount * (this.tax_rate_percent));
                  // Tax not included, calculate and add tax
                  totalTaxExcludedAmount += productTaxedAmount;//productAmount;
                  totalTaxExcluded += productTax;
                  subtotalAmount += productAmountBeforeTax;
                  return tableAcc + productAmount + productTax ;// - productDiscount;

                }

                
            }, 0);
            return acc + tableTotal;
        }
        return acc;
    }, 0).toFixed(2));

    // Now you can use totalTaxIncluded and totalTaxExcluded as needed
    
    var tax_include_total_amount = document.getElementById('all_row_tax_included_total_amount');
    var tax_include_total = document.getElementById('all_row_tax_included_total');
    var tax_exclude_total_amount = document.getElementById('all_row_tax_excluded_total_amount');
    var tax_exclude_total = document.getElementById('all_row_tax_excluded_total');

    const productSummaryRow = this.rows.controls.find(row => row.value.row_type === 'productSummary');
    if (productSummaryRow) {
      
      productSummaryRow.get('tax_rate')?.setValue(parseFloat((totalTaxIncluded+totalTaxExcluded).toFixed(2)));
      
      // if(totalDiscountAmount>0){
        productSummaryRow.get('discount_rate')?.setValue(parseFloat(totalDiscountAmount.toFixed(2)));
      // }
      
      // if(subtotalAmount){
        productSummaryRow.get('sub_total')?.setValue(parseFloat(subtotalAmount.toFixed(2)));
      // }
      
    }

    //Tax Included
    if(tax_include_total_amount){
      tax_include_total_amount.textContent = this.paymentSign + parseFloat(totalTaxIncludedAmount.toFixed(2));
      tax_include_total_amount.style.display = 'inline';
    }
    if (tax_include_total) {
      tax_include_total.textContent = this.paymentSign + parseFloat(totalTaxIncluded.toFixed(2));
      tax_include_total.style.display = 'inline';
    }

    //Tax Excluded
    if(tax_exclude_total_amount){
      tax_exclude_total_amount.textContent = this.paymentSign + parseFloat(totalTaxExcludedAmount.toFixed(2));
      tax_exclude_total_amount.style.display = 'inline';
    }
    if (tax_exclude_total) {
      tax_exclude_total.textContent = this.paymentSign + parseFloat(totalTaxExcluded.toFixed(2));
      tax_exclude_total.style.display = 'inline';
    }

    //Discount
    var discount_total = document.getElementById('total_discount_amount');

    if (discount_total) {
      discount_total.textContent = this.paymentSign + parseFloat(totalDiscountAmount.toFixed(2));
      discount_total.style.display = 'inline';
    }

    const taxIncludedElement = document.getElementById('include_tax');
    const taxExcludedElement = document.getElementById('exclude_tax');
    //included Amount is 0
    if (totalTaxIncludedAmount === 0 && taxIncludedElement) {
        taxIncludedElement.style.display = 'none';
    } else if (taxIncludedElement) {
        taxIncludedElement.style.display = 'inline';
    }
    //excluded Amount is 0
    if (totalTaxExcludedAmount === 0 && taxExcludedElement) {
      taxExcludedElement.style.display = 'none';
    } else if (taxExcludedElement) {
        taxExcludedElement.style.display = 'inline';
    }
    

    return subtotalWithTaxDiscount;
}

  /**
   * Handling Address Google address into Formcontrol value
   * @param delivery_address //Data comes from google autocomplete
   */
  handleDeliveryAddressChange(address: any) {
    const formatted_address = this.googleMapService.handleFormattedAddress(address);
    this.quoteForm.controls['delivery_address'].setValue(formatted_address);
  }

 
  saveQuote(state_status: any) {
    
    console.log("This ther form submit");
    
    this.submitted = true;
    
    if(this.existingOrder){
      showToast('Opps!', 'You have already accepted the quote.', 'warning');// data.id is to highlight updated row
    }else{
      if (this.quoteForm.valid) {
        //Y-m-d H:i
        this.isAccepted = true;
        this.isDisabled = true;
        
        this.inputFormValues = {
          user_id: this.quoteForm.value.user_id,
          organisation_id: this.quoteForm.value.organisation_id,
          quote_id: this.quoteForm.value.quote_id,
          order_date: Date(),
          delivery_address: this.quoteForm.value.delivery_address,
          fulfillment_status: 0,
          state: state_status,
          ...this.quoteRowForm.value,
        }
  
        console.log("SUBMITTED FORM FULL DATA:", this.inputFormValues);
        // return;
          this.quote_form['id'].disable();
          this.quotesService.postOrderData(this.inputFormValues).subscribe(
            (data: any) => {
              this.router.navigate([`/quote/${this.token}`]);
              showToastWithHighlightRow('Add!', 'Order made successfully.', 'success', data.id, 'bg-soft-success');// data.id is to highlight updated row
              setTimeout(() => {
                removeHighlight('bg-soft-success');
              }, 3000);
              window.location.reload();

            },
            (error) => {
              console.log(error);
              if (error.status === 409) {
                this.toster.warning('Quote already exists!', 'Error');
              } else {
                if (error.indexOf('Conflict') !== -1) {
                  this.toster.warning('Quote already exists!', 'Error');
                }
              }
            }
          )
        
        setTimeout(() => {
          // this.quoteForm.reset();
        }, 2000);
      } else if (this.quoteForm.invalid) {
        return;
      }
    }
  
  }

  loadQuoteData(quote_id:any) {
    this.quoteId = quote_id;
    if (!quote_id) return;
    this.quotesService.getSingleQuoteData(quote_id).subscribe(
      (data: any) => {
        this.quoteDetails = data;
        console.log("LoadQuote: ", this.quoteDetails);
        this.present_state = data.state;
        
        const state = this.present_state;
        
        if(state === QUOTE_TEMPLATE_STATE.ACCEPTED) {
          this.isAccepted = true;
          this.isDisabled = true;
        }
        if(state === QUOTE_TEMPLATE_STATE.DECLINED) {
          this.isDeclined= true;
          this.isDisabled = true;
        }
        // Set the name and id of the quote
        // this.quoteForm.controls['id'].setValue(data.id);
        this.quoteForm.controls['delivery_address'].setValue(data.recipient_delivery_address);
        
        
        // Retrieve the rows FormArray
        const rowsFormArray = this.quoteForm.get('rows') as FormArray;
        // rowsFormArray.clear();  // Clear any existing rows

        // Iterate through the rows in the data
        this.showDesignSelector = false;
        data.rows.forEach((row: any) => {
          const rowFormGroup = this.fetchRowFormGroup(row);
          // console.log("RowFormGroup: ", rowFormGroup)
          // rowsFormArray.push(rowFormGroup);
          // this.rows.push(rowFormGroup)
        });

        this.cdr.detectChanges();
      },
      (error) => {
        console.log(error);
        this.toster.error('Failed to load Quote data.', 'Error');
      }
    );
  }
  
  loadExistingOrder(order_id:any) {
    // this.quoteId = quote_id;
    // if (!quote_id) return;
    this.quotesService.getQuoteWithOrderProductData(order_id).subscribe(
      (data: any) => {
        console.log("Load Order Data: ", data);
        // this.quoteDetails = data;
        this.present_state = data.state;

        const state = this.present_state;
        // ORDER IS ONLY ACCEPTED WHILE ORDER HAS PLACED SO CHECK FROM ORDER
        if(state === ORDER_STATE.RECEIVED) {
          this.isDisabled = true;
          this.isAccepted = true;
          this.isOrderReceived = true;
        }
        
        if(state === ORDER_STATE.APPROVED) {
          this.isDisabled = true;
          this.isAccepted = true;
          this.isOrderApproved= true;
        }

        if(state === ORDER_STATE.CANCELLED) {
          this.isDisabled = true;
          this.isAccepted = true;
          this.isOrderCancelled = true;
        }
        // Set the name and id of the quote
        // this.quoteForm.controls['id'].setValue(data.id);
        this.quoteForm.controls['delivery_address'].setValue(data.delivery_address);
        
        
        // Retrieve the rows FormArray
        const rowsFormArray = this.quoteForm.get('rows') as FormArray;
        // rowsFormArray.clear();  // Clear any existing rows

        // Iterate through the rows in the data
        this.showDesignSelector = false;
        data.rows.forEach((row: any) => {
          const rowFormGroup = this.fetchRowFormGroup(row);
          // console.log("RowFormGroup: ", rowFormGroup)
          // rowsFormArray.push(rowFormGroup);
          // this.rows.push(rowFormGroup)
        });

        this.cdr.detectChanges();
      },
      (error) => {
        console.log(error);
        this.toster.error('Failed to load Order data.', 'Error');
      }
    );
  }
  
  fetchRowFormGroup(row: any): FormGroup {
    let rowFormGroup: FormGroup;
    
    if(row.row_text_data) {
      row.row_text_data = replacePlaceHolders(row.row_text_data, this);
    }

    if(row.row_overlay_text) {
      row.row_overlay_text= replacePlaceHolders(row.row_overlay_text, this);
    }

    switch (row.row_type) {
      case 'textEditor':
        rowFormGroup = this.formBuilder.group({
          id: [row.id],
          row_index: [row.row_index],
          row_type: ['textEditor'],
          row_background_color: [row.row_background_color || ''],
          row_height: [row.row_height || ''],
          row_text_data: [row.row_text_data || '']
        });
        this.rows.push(rowFormGroup);
        break;

      case 'imageEditor':
        rowFormGroup = this.formBuilder.group({
          id: [row.id],
          row_index: [row.row_index],
          row_type: ['imageEditor'],
          row_background_color: [row.row_background_color || ''],
          row_height: [row.row_height || ''],
          row_background_image: [row.row_background_image || this.fallback_image_url],
          row_overlay_text: [row.row_overlay_text || '']
        });
        this.rows.push(rowFormGroup);
        break;

      case 'productTable':
        const productsFormArray = this.formBuilder.array(
          (row.products || []).map((product: any) => this.fetchProductFormGroup(product))
        );
        rowFormGroup = this.formBuilder.group({
          id: [row.id],
          row_index: [row.row_index],
          row_type: ['productTable'],
          row_background_color: [row.row_background_color || ''],
          row_height: [row.row_height || ''],
          tax_column: [row.tax_column || ''],
          products: productsFormArray
        });
        this.rows.push(rowFormGroup);
        break;

      case 'productSummary':
        this.shippingRate = parseFloat(row.shipping_rate.toFixed(2));
        this.discountRate = parseFloat((row.discount_rate/row.sub_total).toFixed(2));
        rowFormGroup = this.formBuilder.group({
          id: [row.id],
          row_index: [row.row_index],
          row_type: ['productSummary'],
          row_background_color: [row.row_background_color || ''],
          row_height: [row.row_height || ''],
          shipping_row: [row.shipping_row || ''],
          sub_total: [row.sub_total],
          tax_rate: [row.tax_rate],
          discount_rate: [row.discount_rate],
          shipping_rate: [row.shipping_rate],
          total_amount: [row.total_amount]
        });
        this.rows.push(rowFormGroup);
        break;

      default:
        rowFormGroup = this.formBuilder.group({
          row_index: [row.row_index],
          row_type: ['unknown']
        });
        this.rows.push(rowFormGroup);
        console.warn('Unknown row type encountered:', row.row_type);
    }

    return rowFormGroup;
  }

  
  fetchProductFormGroup(product: any): FormGroup {
    return this.formBuilder.group({
      id: [product.id],
      product_id: [product.product_id],
      product_mpn: [product.product_mpn],
      product_name: [product.product_name],
      product_description: [product.product_description],
      product_rate: [product.product_rate],
      product_quantity: [product.product_quantity],
      product_amount: [product.product_amount],
      recurring: [product.recurring],
      recurring_time: [product.recurring_time],
      recurring_cycle: [product.recurring_cycle],
      discount: [product.discount],
      discount_type: [product.discount_type],
      discount_value: [product.discount_value],
      taxable: [product.taxable],
      taxable_rate: [product.taxable_rate],
    });
  }


  /**
   * Confirmation mail model
   */
  declineId: any;
  confirm(content: any, id: any) {
    this.declineId = id;
    this.modalService.open(content, { centered: true });
  }

  // Delete Data
  declineQuote(id: any) {
    if (id) {

      this.router.navigate([`/commerce/quotes/view/${this.token}`]);
      showToastWithHighlightRow('Declined!', 'Your quote has been declined.', 'success', id, 'bg-soft-danger');

      const statusUpdate = {
        state:  QUOTE_TEMPLATE_STATE.DECLINED
      }; //
      //Action to remove quote from database after removing class
      this.quotesService.patchQuoteState(statusUpdate, id).subscribe({
        next: data => {
          this.isDeclined = true;
         },
        error: err => {
          this.econtent = JSON.parse(err.error).message;
        }
      });

      window.location.reload();

    } 
 
  }

  /**
   * 
   * @param stateName 
   * @returns 
   */
  getApprovalStatus(stateName: string): string {
      return stateName === 'Received' ? 'Pending' : stateName;
  }

  getColor():string {
    if (this.isAccepted && (!this.isOrderReceived && !this.isOrderApproved && !this.isOrderCancelled)) return 'success';
      if (this.isDeclined) return 'danger';
      if (this.isAccepted && this.isOrderReceived) return 'warning';
      if (this.isAccepted && this.isOrderApproved) return 'success';
      if (this.isAccepted && this.isOrderCancelled) return 'danger';
      return 'warning';
  }
  getIconClass(): string {
      if (this.isAccepted && (!this.isOrderReceived && !this.isOrderApproved && !this.isOrderCancelled)) return 'ri-checkbox-circle-fill';
      if (this.isDeclined) return 'ri-close-circle-fill';
      if (this.isAccepted && this.isOrderReceived) return 'ri-information-fill';
      if (this.isAccepted && this.isOrderApproved) return 'ri-checkbox-circle-fill';
      if (this.isAccepted && this.isOrderCancelled) return 'ri-close-circle-fill';
      return 'ri-information-fill';
  }

  getTitle(): string {
      if (this.isAccepted && (!this.isOrderReceived && !this.isOrderApproved && !this.isOrderCancelled)) return 'Congratulations!';
      if (this.isDeclined) return 'Unfortunately!';
      if (this.isAccepted && this.isOrderReceived) return 'Pending!';
      if (this.isAccepted && this.isOrderApproved) return 'Congratulations!';
      if (this.isAccepted && this.isOrderCancelled) return 'Unfortunately!';
      return 'Information!';
  }

  getSubTitle(): string {
    if (this.isAccepted) return 'You have accepted the quote.';
    if (this.isDeclined) return 'You have declined the quote.';
    return 'You have successfully read this important quote details';
  }

  getConculsionText(): string {
    if (this.isAccepted && (!this.isOrderReceived && !this.isOrderApproved && !this.isOrderCancelled)) return 'Congratulations!';
    if (this.isDeclined) return 'For a new order, please request a fresh quotation.';
    if (this.isAccepted && this.isOrderReceived) return 'Please wait for approval.';
    if (this.isAccepted && this.isOrderApproved) return 'And your order has been approved.';
    if (this.isAccepted && this.isOrderCancelled) return 'But your order has been cancelled.';
    return '';
  }


  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}