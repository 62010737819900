import { environment } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

declare const google: any;

export class GoogleMapService {

  isMapLoaded = false;
  options: any = {
    componentRestrictions: { country: 'AU' }
  };

  constructor() {
    this.initGoogleMap();  
  }
  initGoogleMap() {
    if (document.getElementById('googleMap') == null) {
      const mapsScript = document.createElement('script');
      mapsScript.setAttribute('async', '');
      mapsScript.setAttribute('id', 'googleMap');
      mapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.GOOGLE_MAP_API_KEY + '&&libraries=places&language=en';

      mapsScript.addEventListener('load', () => {
        this.isMapLoaded = true;
      });
      document.head.appendChild(mapsScript);
    } else {
      console.log('Google Map Script Already Loaded...!');
      this.isMapLoaded = true;
    }
  }

  /**
   * Takes address from google autocomplete
   * @param address 
   * @returns 
   */
  handleFormattedAddress(address: Address) {
    return address.formatted_address;
  }

  /**
   * 
   * @param address 
   * @param zoom 
   */
  viewGoogleMap(address: Address, zoom: number) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: address }, function (
      results: { geometry: { location: any } }[],
      status: string
    ) {
      if (status === google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();

        const map = new google.maps.Map(document.getElementById('map'), {
          center: { lat: lat, lng: lng },
          zoom: zoom,
        });

        new google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: map,
          title: address,
        });
      } else {
        console.error(
          'Geocode was not successful for the following reason: ' + status
        );
      }
    });
  }


  openGoogleMap(address: string) {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    // window.open(mapsUrl, '_blank');
    return mapsUrl;
  }
}
