import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';

// Auth
import { AuthGuard } from './core/guards/auth.guard';
import { AlwaysAllowGuard } from './core/guards/always-allow.guard';
import { QuoteComponent } from './views/quote/quote.component';
import { LockScreenComponent } from './account/lock-screen/lock-screen.component';
import { LockGuard } from './core/guards/lock-guard';

const routes: Routes = [
  { path: "quote/:token", component: QuoteComponent, canActivate:[AlwaysAllowGuard] },
  { path: '', component: LayoutComponent, loadChildren: () => import('./evergrow/evergrow.module').then(m => m.EvergrowModule), canActivate: [AuthGuard,LockGuard] },
  { path: 'lock-screen', component:LockScreenComponent, canActivate: [AuthGuard,LockGuard] },
  { path: 'auth', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)  }, 
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
