import { MenuItem } from './menu.model';
export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'ri-dashboard-2-line',
    link: '/',
     
  },
  {
    id: 3,
    label: 'MENUITEMS.CUSTOMERS.TEXT',
    icon: 'ri-user-line', 
    subItems: [
      {
        id: 4,
        label: 'MENUITEMS.CUSTOMERS.LIST.CUSTOMERLIST',
        link: '/customers',
        parentId: 3
      },
    ]
   }
,
  {
    id: 5,
    label: 'MENUITEMS.USERS.TEXT',
    icon: 'ri-shield-user-line',
    subItems: [
      {
        id: 6,
        label: 'MENUITEMS.USERS.LIST.USERLIST',
        link: '/users',
        parentId: 5
      },
    ]
   }
]; 