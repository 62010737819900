import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { showToastNoTimer } from "../highlight-row/highlight-row";


@Injectable({
    providedIn: 'root'
})

export class HandlingError {

    //  The different types of error codes
    //200 OK
    //201 Created
    //204 No Content 
    //400 Bad Request 
    //401 Unauthorized // Password Doesn't match //token expired
    //403 Forbidden // Account Inactive Password Doesn't match //token expired
    //404 Not Found // Account not Found
    //409 Conflict // Account already exist
    //422 Unprocessable Entity // Validation failed
    //500 internal server error
    //503 Service unavailable

    constructor(private toster: ToastrService) { }

    /**
     * 
     * @param error 
     */
    handleAccountError(error: any) {
        const status = error.status;
        switch (status) {
            case 400: // Bad Request
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 401: // Unauthorized
                showToastNoTimer('Warning!', 'You are not authorized to perform this action. Please log in and try again.', 'warning');
                break;
            case 403: // Forbidden
                if (error.message.indexOf('Inactive') !== -1) {
                    showToastNoTimer('Oops!', "Your account is inactive. Please check your email for activation.", 'warning'); //Account inactive
                } else if (error.message.indexOf(`Password Didn't Match`) !== -1) {
                    showToastNoTimer('Oops!', "Your password didn't match.", 'warning'); //password didn't match
                } else {
                    showToastNoTimer('Warning!', "You do not have permission to access this resource.", 'warning');
                }
                break;
            case 404: // Not Found
                if (error.message.indexOf('Not Found') !== -1) {
                    showToastNoTimer('Oops!', "We couldn't find your account.", 'warning'); // Account not found
                } else if (error.message.indexOf('Already Activated') !== -1) {
                    showToastNoTimer('Oops!', "Your account is already activated.", 'warning'); // Account not found
                } else if (error.message.indexOf('Invalid Token') !== -1) {
                    showToastNoTimer('Oops!', "Your token is invalid.", 'warning'); // Account not found
                } else if (error.message.indexOf('Token Expired') !== -1) {
                    showToastNoTimer('Oops!', "Your token has expired.", 'warning'); // Account not found
                } else if (error.message.indexOf('Old Password') !== -1) {
                    showToastNoTimer('Opps!', "You have typed your old password.", 'warning'); // Account not found
                } else {
                    showToastNoTimer('Oops!', "The resource you are looking for could not be found.", 'warning');
                }

                // // If you want to use COOKIE BASED ERROR HANDLING ***** it has different design
                // this.cookieService.set('error', 'Not Found', 1, '/');
                // this.cookieService.set('error', 'Inactive', 1, '/');
                // this.cookieService.set('error', 'Invalid Token', 1, '/');
                // this.cookieService.set('error', 'Token Expired', 1, '/');
                // this.cookieService.set('error', 'Old Password', 1, '/');
                // this.cookieService.set('token', token, 1, '/');
                // this.cookieService.set('error', 'true', 1, '/');

                break;
            case 409: // Conflict 
                if (error.message.indexOf('Already Exist') != -1) {
                    showToastNoTimer('Oops!', "Your account already exist.", 'warning'); //Account already exist
                } else {
                    showToastNoTimer('Warning!', "The request could not be completed due to a conflict with the current state of the resource.", 'warning');
                }
                break;
            case 422: // Unprocessed Entity
                showToastNoTimer('Validation failed!', "Please check the input and try again.", 'warning');//Validation failed
                break;
            case 500: // Internal Server Error
                this.toster.error('An unexpected error occurred on the server. Please try again later.', 'Error');
                break;
            case 503: //Service unavailable
                this.toster.error('The server is currently unavailable. Please try again later.', 'Error');
                break;
            default:
                this.toster.warning('An unexpected error occurred', 'Warning');
        }

        // // If you want to use COOKIE BASED ERROR HANDLING ROUTING ***** it has different design
        // this.router.navigate(['/auth/success'], { queryParams: { msg: 'signin'} });
        // this.router.navigate(['/auth/success'], { queryParams: { msg: 'forgotpassword' } });
        // this.router.navigate(['/auth/success'], { queryParams: { msg: 'resetpassword'} });
        // this.router.navigate(['/auth/success'], { queryParams: { msg: 'activation'} });

    }

    /**
     * 
     * @param error 
     */
    handleBasicError(error: any) {
        const status = error.status;
        switch (status) {
            case 400: // Bad Request
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 401: // Unauthorized
                showToastNoTimer('Warning!', 'You are not authorized to perform this action. Please log in and try again.', 'warning');
                break;
            case 403: // Forbidden
                showToastNoTimer('Warning!', "You do not have permission to access this resource.", 'warning');
                break;
            case 404: // Not Found
                showToastNoTimer('Oops!', "The resource you are looking for could not be found.", 'warning');
                break;
            case 409: // Conflict 
                showToastNoTimer('Warning!', "The request could not be completed due to a conflict with the current state of the resource.", 'warning');
                break;
            case 422: // Unprocessed Entity
                showToastNoTimer('Validation failed!', "Please check the input and try again.", 'warning');
                break;
            case 500: // Internal Server Error
                this.toster.error('An unexpected error occurred on the server. Please try again later.', 'Error');
                break;
            case 503: //Service unavailable
                this.toster.error('The server is currently unavailable. Please try again later.', 'Error');
                break;
            default:
                this.toster.warning('An unexpected error occurred', 'Warning');

        }
    }

    /**
     * 
     * @param error 
     */
    handleDefaultError(error: any) {
        const status = error.status;
        switch (status) {
            case 400: // Bad Request
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 401: // Unauthorized
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 403: // Forbidden
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 404: // Not Found
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 409: // Conflict 
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 422: // Unprocessed Entity
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 500: // Internal Server Error
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            case 503: //Service unavailable
                showToastNoTimer('Warning!', error.message, 'warning');
                break;
            default:
                this.toster.warning('An unexpected error occurred', 'Warning');
        }


    }
    /**
  * 
  * @param error 
  */
    handleCommonError(error: any, modelName: string) {
        const status = error.status;
        let hasDetail = error.message.indexOf('detail') !== -1 ? true : false; // error in case of fetching details list
        let hasPricing = error.message.indexOf('product pricing') !== -1 ? true : false; // error in case of product pricing list
        let hasList = error.message.indexOf('list') !== -1 ? true : false; // error in case of product pricing list
        let hasMPN = error.message.indexOf('MPN or SKU') !== -1 ? true : false; // error in case of product pricing list
        let isMulti = error.message.indexOf('delete some') !== -1 ? true : false; // error in case of multiple delete
        switch (status) {
            case 400: // Bad Request
                if (error.message.toLowerCase().indexOf('fetch') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't fetch this ${modelName}${hasPricing ? ' pricing.' : ''}${hasDetail ? ' details' : ''}${hasList ? 'list' : '.'} `, 'warning'); // Data cant fetch
                }
                else if (error.message.toLowerCase().indexOf('create') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't create this ${modelName}${hasPricing ? ' pricing.' : ''}${hasDetail ? ' details' : ''}${hasList ? 'list' : '.'}`, 'warning'); // Data can't create
                }
                else if (error.message.toLowerCase().indexOf('update') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't update this ${modelName}${hasPricing ? ' pricing.' : ''}${hasDetail ? ' details' : ''}${hasList ? 'list' : '.'}`, 'warning'); // Data can't update
                }
                else if (error.message.toLowerCase().indexOf('delete') !== -1) {
                    if (error.message.toLowerCase().indexOf('image') !== -1) {
                        showToastNoTimer('Oops!', `We couldn't delete this ${modelName} image`, 'warning'); // Can't delete image of  specific model
                    }
                   else if (error.message.toLowerCase().indexOf('associated records') !== -1) {

                        showToastNoTimer('Oops!', `We couldn't delete ${isMulti?"some of the":"this"} ${modelName}  due to associated records ${error.message.substring(error.message.indexOf('in:'))}`, 'warning'); // associate records data delete error
                    }
                    
                    else {
                        showToastNoTimer('Oops!', `We couldn't delete this ${modelName}${hasPricing ? ' pricing.' : ''}${hasDetail ? ' details' : ''}${hasList ? 'list' : '.'}`, 'warning'); // Data can't delete
                    }
                }
                else if (error.message.toLowerCase().indexOf('validate') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't validate this ${modelName} request`, 'warning'); // Data not validate
                }
                else if (error.message.toLowerCase().indexOf('validation failed') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't validate this ${modelName}`, 'warning'); // Data not validate
                }
                else if (error.message.toLowerCase().indexOf('list') !== -1) {
                    if (modelName == 'branch') {
                        showToastNoTimer('Oops!', `We couldn't list ${modelName} by organization`, 'warning'); // Data couldn't list
                    }
                    else {
                        showToastNoTimer('Oops!', `We couldn't list ${modelName}${hasPricing ? ' pricing.' : ''}${hasDetail ? ' details' : ''}${hasList ? 'list' : '.'}`, 'warning'); // Data couldn't list
                    }
                }
                else if (error.message.toLowerCase().indexOf('file') !== -1) {
                    showToastNoTimer('Oops!', `we couldn't upload this ${modelName} file`, 'warning'); // file has some error
                }
                else {
                    showToastNoTimer('Warning!', error.message, 'warning');
                }
                break;
            case 401: // Unauthorized
                showToastNoTimer('Warning!', 'You are not authorized to perform this action. Please log in and try again.', 'warning');
                break;
            case 403: // Forbidden
                showToastNoTimer('Warning!', "You do not have permission to access this resource.", 'warning');
                break;
            case 404: // Not Found
                if (error.message.toLowerCase().indexOf('not found') !== -1) {
                    showToastNoTimer('Oops!', `We couldn't find this ${modelName}.`, 'warning'); // Data not found
                } else {
                    showToastNoTimer('Oops!', "The resource you are looking for could not be found.", 'warning');
                }
                break;
            case 409: // Conflict 
                if (error.message.toLowerCase().indexOf('already exist') != -1) {
                    showToastNoTimer('Oops!', `This ${modelName}${hasMPN ?" with same MPN or SKU":""} ${hasPricing?" pricing":""} ${hasDetail?" detail":""} already exist.`, 'warning'); //Data already exist
                } else {
                    showToastNoTimer('Warning!', "The request could not be completed due to a conflict with the current state of the resource.", 'warning');
                }
                break;
            case 422: // Unprocessed Entity
                showToastNoTimer('Validation failed!', "Please check the input and try again.", 'warning');
                break;
            case 500: // Internal Server Error
                this.toster.error('An unexpected error occurred on the server. Please try again later.', 'Error');
                break;
            case 503: //Service unavailable
                this.toster.error('The server is currently unavailable. Please try again later.', 'Error');
                break;
            case 'UnknownEndpoint': //unknown host or endpoint specially when file upload
                this.toster.error(error.message, 'Warning', { disableTimeOut: true });
                break;
            default:
                this.toster.warning('An unexpected error occurred', 'Warning');
        }
    }
    //check the format of uploaded image is valid or not
    checkFileFormat(file: any) {
        const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedFormats.includes(file.type)) {
            this.toster.error('Please select a valid image format (JPEG, PNG, GIF)!', 'Error');
            return false;
        }
        return true;
    }
}