<!-- ========== App Menu ========== -->
  <div class="app-menu navbar-menu">
    <!-- LOGO Text -->
    <div class="navbar-brand-box">
      <!-- Dark Logo Text-->
      <a routerLink="/" class="logo logo-dark">
          <span class="logo-lg">
            <h4 class="m-4 fs-2 text-dark ff-secondary">EverGrow</h4>
          </span>
      </a>
      <!-- Light Logo Text-->
      <a routerLink="/" class="logo logo-light">
              <h4 class="m-4 fs-2 text-light ff-secondary">EverGrow</h4>
      </a>
  </div>

  <div id="scrollbar">
      <div class="container-fluid">

          <div id="two-column-menu" >
            
            <ul class="twocolumn-iconview" data-simplebar="init">
                  <div class="simplebar-wrapper" style="margin: 0px;">
                    <div class="simplebar-mask">
                      <div class="simplebar-offset" style="right: 50px; bottom: 50px;">
                        <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
                          <div class="simplebar-content pt-1">
                            <a href="/" class="logo">
                              <img src="assets/images/icon.png" alt="" height="35">
                            </a>
                            <ng-container *ngFor="let item of menuItems$ | async">
                              <li *ngIf="!item.isTitle && !item.isLayout" 
                              [ngStyle]="{'display': shouldDisableToggle(item.label) ? 'none' : 'block'}">
                                <a *ngIf="hasItems(item)" href="javascript:void(0);" class="nav-icon" style="height:100%; width:100%; line-height:0.8rem; margin:0px; padding:10px; flex-direction:column;" 
                                  [attr.subitems]="item.collapseid" 
                                  (click)="toggleItem($event)"
                                  [ngStyle]="{'pointer-events': shouldDisableToggle(item.label) ? 'none' : 'auto'}"
                                  [ngClass]="shouldDisableToggle(item.label) ? 'text-muted' : 'text-white-50'"
                                  >
                                  <i class="{{ item.icon }}"></i><br/>
                                  <span class="mb-0 mb-2 mt-0" style="font-size:10px;">{{item.label | translate}}</span>

                                  <span class="mb-0 mb-2 mt-0 badge rounded-pill badge-outline-warning" style="font-size:8px;"
                                  [ngStyle]="{'display': shouldDisableToggle(item.label) ? 'block' : 'none'}"
                                  [ngClass]="shouldDisableToggle(item.label) ? 'text-muted' : 'text-white-50'">Upcoming</span>
                                </a>
                                <a *ngIf="!hasItems(item)" [routerLink]="item.link" [attr.subitems]="item.collapseid" class="nav-icon" style="height:100%; width:100%; line-height:0.8rem; margin:0px; padding:10px; flex-direction:column;"
                                  (click)="toggleParentItem($event)"
                                  [ngStyle]="{'pointer-events': shouldDisableToggle(item.label) ? 'none' : 'auto'}"
                                  [ngClass]="shouldDisableToggle(item.label) ? 'text-muted' : 'text-white-50'"
                                  >
                                  <i class="{{ item.icon }}"></i><br/>
                                  <span class="mb-0 mb-2 mt-0" style="font-size:10px;">{{item.label | translate}}</span>
                                  <span class="mb-0 mb-2 mt-0 badge rounded-pill badge-outline-warning" style="font-size:8px;"
                                  [ngStyle]="{'display': shouldDisableToggle(item.label) ? 'block' : 'none'}"
                                  [ngClass]="shouldDisableToggle(item.label) ? 'text-muted' : 'text-white-50'">Upcoming</span>
                                </a>
                              </li>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
          </div>

          <ul class="navbar-nav" id="navbar-nav" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px;">
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                  <ngx-simplebar class="simplebar-content-wrapper" style="max-height: 100vh">
                    <div class="simplebar-content" style="padding: 0px;">
                      <li class="menu-title"><span data-key="t-menu">Menu</span></li>
                      <li class="nav-item" *ngFor="let item of menuItems$ | async">
                        <ng-container >
                      <div class="collapse menu-dropdown" id="{{item.collapseid}}" [attr.data-name]="item.label | translate" aria-expanded="true">
                          <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column p-0" aria-expanded="false">
                            <span class="mb-0 mb-2 mt-2 ps-4 fs-5 text-light ff-secondary" >{{item.label | translate}}</span>
                            <li *ngFor="let subitem of item.subItems" class="nav-item ps-4" routerLinkActive="bg-soft-light">
                              <!-- [ngStyle]="{'display': shouldDisableToggle(subitem.label) ? 'none' : 'block'}>" -->

                              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                                [attr.data-parent]="subitem.parentId" (click)="updateActive($event)"
                                [ngStyle]="{'pointer-events': shouldDisableToggle(subitem.label) ? 'none' : 'auto'}"
                                [ngClass]="shouldDisableToggle(subitem.label) ? 'text-muted' : 'text-white-50'"
                                >{{ subitem.label | translate}}
                                  <span class="badge badge-pill {{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text | translate}}</span>
                                  <span class="mb-0 mb-2 mt-0 badge rounded-pill badge-outline-warning" style="font-size:8px;"
                                    [ngStyle]="{'display': shouldDisableToggle(subitem.label) ? 'block' : 'none'}"
                                    [ngClass]="shouldDisableToggle(subitem.label) ? 'text-muted' : 'text-white-50'">Upcoming</span>
                              </a>
                              <a *ngIf="hasItems(subitem)" class="nav-link ecomm" data-bs-toggle="collapse" [attr.aria-expanded]="false" href="javascript:void(0);"
                                [attr.data-parent]="subitem.parentId" (click)="toggleSubItem($event)">
                                {{ subitem.label | translate}}
                                <span class="badge badge-pill {{subitem.badge.variant}}" *ngIf="subitem.badge">{{subitem.badge.text | translate}}</span>
                              </a>

                              <div class="collapse menu-dropdown sub-menu" *ngIf="hasItems(subitem)" id="{{item.collapseid}}">
                                <ul class="nav nav-sm flex-column p-0">
                                  <li *ngFor="let subSubitem of subitem.subItems" class="nav-item ps-4" routerLinkActive="bg-soft-light">

                                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                                    class="nav-link" (click)="updateActive($event)" *ngIf="!hasItems(subSubitem)">
                                      {{ subSubitem.label | translate }}
                                      <span class="badge badge-pill {{subSubitem.badge.variant}}" *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                        | translate}}</span>
                                    </a>

                                    <a *ngIf="hasItems(subSubitem)" class="nav-link" href="javascript:void(0);"
                                      [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleExtraSubItem($event)">
                                      {{ subSubitem.label | translate}}
                                      <span class="badge badge-pill {{subSubitem.badge.variant}}" *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                        | translate}}</span>
                                    </a>

                                    <div class="collapse menu-dropdown extra-sub-menu" *ngIf="hasItems(subSubitem)">
                                      <ul *ngIf="hasItems(subSubitem)" class="nav nav-sm flex-column" aria-expanded="false">
                                        <li *ngFor="let subSubitem1 of subSubitem.subItems" class="nav-item">
        
                                          <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link"
                                          class="nav-link" (click)="updateActive($event)">
                                            {{ subSubitem1.label | translate }}
                                          </a>
                                          
                                        </li>
                                      </ul>
                                    </div>
                                    
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                      </li> <!-- end Dashboard Menu -->
                    </div>
                  </ngx-simplebar>
                </div>
              </div>
              <div class="simplebar-placeholder" style="width: auto; height: 183px;"></div>
            </div>
          </ul>
      </div>
      <!-- Sidebar -->
  </div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay"></div>
